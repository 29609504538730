import { Modal } from 'assets/components/modal';
import { useForm } from 'assets/form';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { UpdatePharmacyDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { CheckboxBase } from 'assets/components/checkbox/CheckboxBase';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import { TouchableOpacity, View } from 'react-native';
import states from '../../../../components/PatientFormModal/states';
import { AdvancedDropDownField } from '../../../../components/advanced-dropdown';
import {
  getPharmacy,
  setCountry,
  setPharmacy,
  setShowModalContactInfo,
} from '../pharmacy-settings-actions';
import {
  CountryCode,
  usePharmacySettingsState,
} from '../pharmacy-settings-store';
import { ContactInfoItemRendererProps } from './ContactInfoItemRenderer';

export const ContactInfoModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showModalContactInfo, pharmacy, country } =
    usePharmacySettingsState();
  const styles = useStyles();
  const theme = useTheme();

  const [isChecked, setIsChecked] = useState(false);
  const countries = [
    { label: 'US', value: 'US' },
    { label: 'CA', value: 'CA' },
  ];

  useEffect(() => {
    methods.reset({
      ...pharmacy,
      shipping_address: {
        ...pharmacy?.shipping_address,
        country: {
          value: pharmacy?.shipping_address?.country,
          label: pharmacy?.shipping_address?.country,
        },
        state: {
          value: pharmacy?.shipping_address?.state,
          label: pharmacy?.shipping_address?.state,
        },
      },
      billing_address: {
        ...pharmacy?.billing_address,
        country: {
          value: pharmacy?.billing_address?.country || '',
          label: pharmacy?.billing_address?.country || '',
        },
        state: {
          value: pharmacy?.billing_address?.state || '',
          label: pharmacy?.billing_address?.state || '',
        },
      },
    });
  }, [pharmacy]);

  const methods = useForm<ContactInfoItemRendererProps>({
    defaultValues: {
      ...pharmacy,
      shipping_address: {
        ...pharmacy?.shipping_address,
        country: {
          value: pharmacy?.shipping_address?.country || '',
          label: pharmacy?.shipping_address?.country || '',
        },
        state: {
          value: pharmacy?.shipping_address?.state || '',
          label: pharmacy?.shipping_address?.state || '',
        },
      },
      billing_address: {
        ...pharmacy?.billing_address,
        country: {
          value: pharmacy?.billing_address?.country || '',
          label: pharmacy?.billing_address?.country || '',
        },
        state: {
          value: pharmacy?.billing_address?.state || '',
          label: pharmacy?.billing_address?.state || '',
        },
      },
    },
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const updatedPharmacy: UpdatePharmacyDto = {
      ...pharmacy,
      ...formValue,
      shipping_address: formValue.shipping_address
        ? {
            ...pharmacy?.shipping_address,
            address1: formValue.shipping_address.address1,
            address2: formValue.shipping_address.address2,
            city: formValue.shipping_address.city,
            postal_code: formValue.shipping_address.postal_code,
            country: formValue.shipping_address.country.value,
            state: formValue.shipping_address.state.value,
          }
        : pharmacy?.shipping_address,
      billing_address: formValue.billing_address
        ? {
            ...pharmacy?.billing_address,
            address1: formValue.billing_address.address1 || '',
            address2: formValue.billing_address.address2 || '',
            city: formValue.billing_address.city || '',
            postal_code: formValue.billing_address.postal_code || '',
            country: formValue.billing_address.country.value || '',
            state: formValue.billing_address.state.value || '',
          }
        : pharmacy?.billing_address,
    };

    if (!_isEqual(updatedPharmacy, pharmacy)) {
      await setPharmacy(updatedPharmacy);
      await getPharmacy();
    }
    setShowModalContactInfo(false);
  };

  const closeModal = () => {
    setShowModalContactInfo(false);
    methods.reset({
      ...pharmacy,
      shipping_address: {
        ...pharmacy?.shipping_address,
        country: {
          value: pharmacy?.shipping_address?.country,
          label: pharmacy?.shipping_address?.country,
        },
        state: {
          value: pharmacy?.shipping_address?.state,
          label: pharmacy?.shipping_address?.state,
        },
      },
      billing_address: {
        ...pharmacy?.billing_address,
        country: {
          value: pharmacy?.billing_address?.country,
          label: pharmacy?.billing_address?.country,
        },
        state: {
          value: pharmacy?.billing_address?.state,
          label: pharmacy?.billing_address?.state,
        },
      },
    });
  };

  const changeCountry = (
    country: CountryCode,
    addressFieldName: 'shipping_address' | 'billing_address',
  ) => {
    const formValue = methods.getValues();
    setCountry(country);

    const updatedAddress = {
      ...formValue[addressFieldName],
      state: null,
    };

    methods.reset({
      ...formValue,
      [addressFieldName]: updatedAddress,
    });
  };

  const setMyTasksCheckboxFilter = () => {
    const formValue = methods.getValues();

    methods.reset({
      ...formValue,
      billing_address: isChecked
        ? formValue.billing_address
        : formValue.shipping_address,
    });

    setIsChecked(!isChecked);
  };

  const isValidEmail = (value: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailPattern.test(value);
  };

  return (
    <Modal
      title={getText('pharmacy-edit-contact-info')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-contact-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-contact-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-contact-info-form-ok-button-modal' },
        hierarchy: 'pharmacy-primary',
        text: getText('ok'),
      }}
      show={showModalContactInfo}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-email-address')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="email"
              type="emailAddress"
              rules={{
                validate: (value: string) => {
                  return isValidEmail(value)
                    ? true
                    : getText('email-is-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-phone-number')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="phone"
              type="telephoneNumber2"
              rules={{
                validate: {
                  value: (value: string) => {
                    return validate.isPhoneNumber(value ? '+1' + value : '')
                      ? true
                      : getText('phone-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-fax-number')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="fax"
              type="telephoneNumber2"
              rules={{
                validate: {
                  value: (value: string) => {
                    return validate.isPhoneNumber(value ? '+1' + value : '')
                      ? true
                      : getText('phone-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textViewAddress}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-mailing-address')}
              </Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="shipping_address.address1"
                  label={getText('street1')}
                  style={styles.inputStyle}
                  rules={{
                    validate: (value) => {
                      return validate.isAddress(value)
                        ? true
                        : getText('street-1-is-not-valid');
                    },
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="shipping_address.address2"
                  label={getText('street2')}
                  style={styles.inputStyle}
                  rules={{
                    validate: (value) => {
                      return value === undefined || validate.isAddress(value)
                        ? true
                        : getText('street-2-is-not-valid');
                    },
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.row}>
              <Form.Column style={styles.textInputWrapper}>
                <TextField
                  name="shipping_address.city"
                  style={styles.inputStyle}
                  label={getText('city')}
                />
              </Form.Column>
              <Form.Column style={styles.dropdownWrapper}>
                <AdvancedDropDownField
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: 44,
                      borderRadius: theme.roundness,
                    }),
                  }}
                  options={states[country]}
                  name="shipping_address.state"
                  getOptionValue={(option) => option.value}
                  placeholderTemplate={getPlaceholderTemplate('state')}
                  singleValueTemplate={getSingleValueTemplate('state')}
                />
              </Form.Column>
            </Form.Row>

            <Form.Row style={styles.row}>
              <Form.Column style={styles.textInputWrapper}>
                <TextField
                  name="shipping_address.postal_code"
                  label={getText('zip-code')}
                  style={styles.inputStyle}
                  rules={{
                    validate: (value) => {
                      return validate.isPostalCode(value, 'any')
                        ? true
                        : getText('zip-code-is-not-valid');
                    },
                  }}
                />
              </Form.Column>
              <Form.Column style={styles.dropdownWrapper}>
                <AdvancedDropDownField
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: 44,
                      borderRadius: theme.roundness,
                    }),
                  }}
                  options={countries}
                  name="shipping_address.country"
                  defaultValue={{ label: 'US', value: 'US' }}
                  getOptionValue={(option) => {
                    return option.value;
                  }}
                  onChange={(option) =>
                    changeCountry(
                      option?.value == 'CA' ? CountryCode.CA : CountryCode.US,
                      'shipping_address',
                    )
                  }
                />
              </Form.Column>
            </Form.Row>
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textViewAddress}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-billing-address')}
              </Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <Form.Row>
              <>
                <Form.Column>
                  <TouchableOpacity onPress={setMyTasksCheckboxFilter}>
                    <View style={styles.checkboxContainer}>
                      <CheckboxBase
                        onPress={setMyTasksCheckboxFilter}
                        isChecked={isChecked}
                      />
                      <Text style={styles.checkboxLabel}>
                        Same as mailing address
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Form.Column>
              </>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="billing_address.address1"
                  label={getText('street1')}
                  style={styles.inputStyle}
                  rules={{
                    validate: (value) => {
                      return validate.isAddress(value)
                        ? true
                        : getText('street-1-is-not-valid');
                    },
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name="billing_address.address2"
                  label={getText('street2')}
                  style={styles.inputStyle}
                  rules={{
                    validate: (value) => {
                      return value === undefined || validate.isAddress(value)
                        ? true
                        : getText('street-2-is-not-valid');
                    },
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.row}>
              <Form.Column style={styles.textInputWrapper}>
                <TextField
                  name="billing_address.city"
                  style={styles.inputStyle}
                  label={getText('city')}
                />
              </Form.Column>
              <Form.Column style={styles.dropdownWrapper}>
                <AdvancedDropDownField
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: 44,
                      borderRadius: theme.roundness,
                    }),
                  }}
                  options={states[country]}
                  name="billing_address.state"
                  getOptionValue={(option) => option.value}
                  placeholderTemplate={getPlaceholderTemplate('state')}
                  singleValueTemplate={getSingleValueTemplate('state')}
                />
              </Form.Column>
            </Form.Row>

            <Form.Row style={styles.row}>
              <Form.Column style={styles.textInputWrapper}>
                <TextField
                  name="billing_address.postal_code"
                  label={getText('zip-code')}
                  style={styles.inputStyle}
                  rules={{
                    validate: (value) => {
                      return validate.isPostalCode(value, 'any')
                        ? true
                        : getText('zip-code-is-not-valid');
                    },
                  }}
                />
              </Form.Column>
              <Form.Column style={styles.dropdownWrapper}>
                <AdvancedDropDownField
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: 44,
                      borderRadius: theme.roundness,
                    }),
                  }}
                  options={countries}
                  name="billing_address.country"
                  defaultValue={{ label: 'US', value: 'US' }}
                  getOptionValue={(option) => {
                    return option.value;
                  }}
                  onChange={(option) =>
                    changeCountry(
                      option?.value == 'CA' ? CountryCode.CA : CountryCode.US,
                      'billing_address',
                    )
                  }
                />
              </Form.Column>
            </Form.Row>
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

type TranslationKey = 'preferred-language' | 'state';

function getPlaceholderTemplate(name: TranslationKey) {
  const theme = useTheme();

  return () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          margin: 0,
          paddingLeft: theme.getSpacing(4),
        }}
      >
        <Text>{getText(name)}</Text>
      </View>
    );
  };
}

function getSingleValueTemplate(name: TranslationKey) {
  return (props: any) => {
    return (
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            margin: 0,
            fontSize: 13,
          }}
        >
          {getText(name)}
        </Text>
        <Text
          style={{
            margin: 0,
            fontSize: 16,
          }}
        >
          {props.data?.label}
        </Text>
      </View>
    );
  };
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: 8,
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  dropdownWrapper: {
    display: 'flex',
    flex: 1,
  },
  textInputWrapper: {
    display: 'flex',
    flex: 3,
  },
  row: {
    alignContent: 'stretch',
  },
  textViewAddress: {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: theme.getSpacing(24),
    height: 24,
    textAlignVertical: 'center',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.getSpacing(24),
    cursor: 'pointer',
    lineHeight: 24,
  },
  checkboxLabel: {
    fontSize: 16,
    lineHeight: 24,
    marginLeft: theme.getSpacing(8),
    color: theme.palette.gray[700],
    fontWeight: '500',
  },
}));
