import React from 'react';
import { ViewStyle, TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { IconButton } from 'assets/components/icon-button';
import { Menu, MenuItem, MenuDivider } from 'react-native-material-menu';
import { IconProps } from 'assets/components/icon';

export type BaseOptionType = {
  disabled?: boolean;
  divider?: boolean;
};

export type MenuOptionType<T> = {
  [K in keyof T]: T[K];
} & BaseOptionType;

interface MenuWrapperProps<T> {
  items: T[];
  onPress: (item: T) => void;
  optionTemplate: (item: T, index?: number) => JSX.Element;
  /**
   * Use anchor if you want to render any custom JSX.Element
   */
  anchor?: JSX.Element;
  /**
   * Use icon prop if you want to render an icon button element
   * Icon button can not be rendered as anchor since they need to implement their own [onPress]
   */
  icon?: IconProps;
  menuStyle?: ViewStyle;
  menuItemStyle?: ViewStyle;
  id?: string;
}

const getDefaultId = () => 'menu-wrapper-' + new Date().getTime().toString();

export const MenuWrapper = <T extends object>({
  anchor,
  items,
  onPress,
  optionTemplate,
  menuStyle,
  menuItemStyle,
  icon,
  id = getDefaultId(),
}: MenuWrapperProps<MenuOptionType<T>>) => {
  if (icon && anchor)
    throw 'MenuWrapper can not have both [icon] and [anchor]. Use icon if you want to render an icon button otherwise for any custom rendering use anchor.';
  const theme = useTheme();
  const styles = useStyles();
  const [showMenu, setShowMenu] = React.useState(false);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const handleOnPress = (item: T) => () => {
    setShowMenu(false);
    onPress(item);
  };

  const menuHoverIn = (event: any) => {
    event.target.style['background-color'] = theme.palette.primary[50];
  };

  const menuHoverOut = (event: any) => {
    event.target.style['background-color'] = '';
  };

  const anchorElement = icon ? (
    <IconButton
      {...icon}
      onPress={handleShowMenu}
      logger={{
        id: `anchor-${id}`,
      }}
      style={{ margin: 0 }}
    />
  ) : (
    <TouchableOpacity onPress={handleShowMenu}>{anchor}</TouchableOpacity>
  );

  return (
    <Menu
      visible={showMenu}
      onRequestClose={() => setShowMenu(false)}
      anchor={anchorElement}
      style={{ ...styles.menu, ...menuStyle }}
      testID={`menu-${id}`}
    >
      {items.map((item, index) => {
        if (item.divider)
          return (
            <View
              style={{ margin: theme.getSpacing(8) }}
              key={`${id}-${index}`}
            >
              <MenuDivider />
            </View>
          );
        return (
          <View
            style={{
              marginRight: 1, // fix bug of menu component library
              marginTop: index === 0 ? theme.getSpacing(8) : undefined,
              marginBottom:
                index === items.length - 1 ? theme.getSpacing(8) : undefined,
              borderRadius: theme.roundness,
            }}
            key={`${id}-${index}`}
          >
            <MenuItem
              onPress={handleOnPress(item)}
              onHoverIn={menuHoverIn}
              onHoverOut={menuHoverOut}
              disabled={item.disabled}
              style={{ ...menuItemStyle }}
              key={`${index}`}
            >
              {optionTemplate(item, index)}
            </MenuItem>
          </View>
        );
      })}
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 40,
    borderColor: theme.palette.gray[200],
    borderWidth: 1,
  },
}));
