import React, { FC, FunctionComponent, PropsWithChildren, useRef } from 'react';
import { GradientView } from 'assets/components/gradient-view';
import { TouchableOpacity, View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { uuid } from 'assets/utils/uuid';
import {
  SettingsIcon,
  MenuIcon,
  LumistryIcon,
  ExternalLinkIcon,
} from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { Avatar } from 'assets/components/avatar';
import { NavigationItems } from './NavigationItems';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { logout } from '../../screens/login/login-actions';
import * as Linking from 'expo-linking';
import { MenuOptionType, MenuWrapper } from './MenuWrapper';
import { PharmacyStoreSelector } from '../select-store/PharmacyStoreSelector';

export interface PharmacyHeaderProps {
  /**
   * GradientId, should *only* be necessary for snapshot tests
   */
  gradientId?: string;
  isUserLoggedIn: boolean;
  userName?: string;
  navigation?: any;
  screens?: {
    name: string;
    label: string;
  }[];
}

export const PharmacyHeader: FunctionComponent<
  PropsWithChildren<PharmacyHeaderProps>
> = ({
  gradientId = uuid(),
  isUserLoggedIn = false,
  userName,
  navigation,
  screens,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);

  const handleNeedHelpPress = () => {
    // TODO: maybe the url should be a configuration
    // for the moment it is hardcoded on the ticket [BLUES-2227]
    Linking.openURL('https://lumistry.helpjuice.com/pharmacies');
  };

  return (
    <>
      <GradientView
        contentHeight={72}
        fillGradientStops={[
          { stopColor: theme.colors.pharmacyPrimary, offset: 0 },
          { stopColor: theme.colors.pharmacyPrimary, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <View style={styles.leftContent}>
            <View style={styles.logo}>
              <LumistryIcon size={50} color={theme.palette.white} />
            </View>
            {isUserLoggedIn && navigation && screens && (
              <NavigationItems navigation={navigation} screens={screens} />
            )}
          </View>
          <View style={styles.rightContent}>
            {isUserLoggedIn && userName ? (
              <View style={{ flexDirection: 'row' }}>
                <PharmacyStoreSelector />
                <TopNavRightIcons navigation={navigation} userName={userName} />
              </View>
            ) : (
              <TouchableOpacity onPress={handleNeedHelpPress}>
                <Text
                  style={{
                    color: theme.palette.white,
                  }}
                >
                  {getText('need-help')}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </GradientView>
    </>
  );
};

const HamburgerMenu: FC<{ navigation: NavigationProp<ParamListBase> }> = ({
  navigation,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const handleMenuItemClick = (item: HamburgerMenuOptionType) => {
    navigation.navigate(item.path);
  };

  const menuItems: MenuOptionType<HamburgerMenuOptionType>[] = [
    {
      path: 'videos',
      text: getText('video-header'),
    },
    {
      path: 'refill-submissions',
      text: getText('refill-submissions-header'),
    },
  ];

  return (
    <MenuWrapper
      onPress={handleMenuItemClick}
      icon={{
        icon: MenuIcon,
        color: theme.palette.white,
        testID: PharmacyHeaderTestIDs.menuButton,
      }}
      items={menuItems}
      optionTemplate={(item, index) => {
        return <Text>{item.text}</Text>;
      }}
      menuItemStyle={{ height: 32 }}
    />
  );
};

const UserMenu: FC<{
  navigation: NavigationProp<ParamListBase>;
  userName: string;
}> = ({ navigation, userName }) => {
  const theme = useTheme();
  const styles = useStyles();

  const handleMenuItemClick = (item: UserMenuItemType) => {
    if (item.path === 'sign-out') logout();
    else navigation.navigate(item.path);
  };

  // commented the lines below because they will be used when those routes will be implemented
  const menuItems: UserMenuItemType[] = [
    // {
    //   path: 'profile-settings',
    //   icon: UserIcon,
    //   text: getText('profile-settings'),
    //   disabled: true,
    // },
    // {
    //   path: 'switch-user',
    //   icon: RepeatIcon,
    //   text: getText('switch-user'),
    //   disabled: true,
    // },
    {
      path: 'sign-out',
      icon: ExternalLinkIcon,
      text: getText('sign-out'),
      disabled: false,
    },
  ];

  return (
    <MenuWrapper
      items={menuItems}
      optionTemplate={(item) => (
        <View style={styles.userMenuOptionContainer}>
          <Icon icon={item.icon} size={16} color={theme.palette.gray[500]} />
          <Text style={styles.userMenuOptionText}>{item.text}</Text>
        </View>
      )}
      anchor={
        <View style={{ paddingTop: theme.getSpacing(4) }}>
          <Avatar name={userName} size={40} />
        </View>
      }
      onPress={handleMenuItemClick}
      menuItemStyle={{ height: 32 }}
      menuStyle={{ marginTop: 50 }}
    />
  );
};

const TopNavRightIcons: FC<{
  navigation: NavigationProp<ParamListBase>;
  userName: string;
}> = ({ navigation, userName }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.rightIconsContainer}>
      <IconButton
        icon={SettingsIcon}
        logger={{ id: 'pharmacy-settings-icon-button' }}
        onPress={() => navigation.navigate('settings')}
        color={theme.palette.white}
        testID={PharmacyHeaderTestIDs.settingsButton}
        style={{ margin: 0 }}
      />
      <View style={{ marginRight: 6 }}>
        <HamburgerMenu navigation={navigation} />
      </View>

      <UserMenu navigation={navigation} userName={userName} />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
    height: '100%',
    flexDirection: 'row',
    boxShadow: 'inset 0px 2px 8px rgba(0, 0, 0, 0.45)',
  },
  leftContent: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    paddingLeft: theme.getSpacing(32),
    flexDirection: 'row',
  },
  rightContent: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'flex-end',
    paddingRight: theme.getSpacing(32),
  },
  navItem: {
    color: theme.palette.white,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
  logo: {
    marginRight: theme.getSpacing(24),
  },
  rightIconsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    paddingLeft: 20,
    borderLeftWidth: 1,
    borderLeftColor: 'rgba(255,255,255,0.5)',
    gap: theme.getSpacing(16),
    alignItems: 'center',
  },
  userMenuOptionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    gap: theme.getSpacing(8),
  },
  userMenuOptionText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
}));

type HamburgerMenuOptionType = {
  path: string;
  text: string;
};

type UserMenuItemType = {
  path: string;
  icon: React.FunctionComponent;
  text: string;
  disabled: boolean;
};

export const PharmacyHeaderTestIDs = {
  settingsButton: 'pharmacy-settings-icon-button',
  menuButton: 'pharmacy-menu-icon-button',
  accountButton: 'pharmacy-account-icon-button',
};
