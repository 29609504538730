import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button } from 'assets/components/button';
import { IconButton } from 'assets/components/icon-button';
import { Text } from 'assets/components/text';
import { ChevronLeftIcon, UserXIcon } from 'assets/icons';
import { makeStyles } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { AppointmentForm } from '../../../schedule/appointment-form/AppointmentForm';
import { submitAppointmentForm } from '../../../schedule/appointment-form/appointment-form-actions';
import { useAppointmentFormState } from '../../../schedule/appointment-form/appointment-form-store';
import { ScheduleDrawerNavigationProp } from '../../../layout/ScheduleDrawer';
import { RootStackNavigationProp } from '../../../navigation/RootNavigation';
import { cancelBooking } from '../../../schedule/appointments-list/appointments-list-actions';
import PharmacyConfirmationModal from '../../../components/PharmacyConfirmationModal';

export const Appointment: FunctionComponent<AppointmentProps> = (props) => {
  const styles = useStyles();
  const { status, locationPatientRecord, isDisabled } =
    useAppointmentFormState();
  const navigation = useNavigation<
    RootStackNavigationProp & ScheduleDrawerNavigationProp
  >();
  const route = useRoute<any>();
  const appointmentId = route.params?.appointmentId;
  const isPrefilled = route.params?.isPrefilled;
  const edit = !!appointmentId;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCancelBooking = async () => {
    setShowModal(false);
    setLoading(true);
    await cancelBooking(appointmentId);
    setLoading(false);
    navigation.navigate('appointments');
  };

  const handleBackButtonPress = () => {
    if (isPrefilled) {
      navigation.navigate('patients');
    } else {
      navigation.goBack();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <IconButton
          size={25}
          logger={{ id: 'go-back-appointment-form-button' }}
          icon={ChevronLeftIcon}
          onPress={handleBackButtonPress}
          style={styles.backButton}
        />
        <Text style={styles.title} selectable>
          {locationPatientRecord
            ? getText('patients')
            : getText('appointments')}
        </Text>
        {edit && (
          <Button
            hierarchy="tertiary-gray"
            style={{
              borderColor: 'transparent',
            }}
            mode="outlined"
            size="small"
            logger={{ id: 'cancel-appointment-button' }}
            icon={UserXIcon}
            onPress={() => setShowModal(true)}
            loading={loading}
          >
            {getText('cancel')}
          </Button>
        )}
        <Button
          hierarchy="pharmacy-primary"
          size="small"
          logger={{ id: 'save-appointment-button' }}
          onPress={submitAppointmentForm}
          loading={status === 'loading'}
          disabled={isDisabled}
        >
          Save
        </Button>
      </View>
      <View>
        <AppointmentForm />
      </View>
      <PharmacyConfirmationModal
        show={showModal}
        message={getText('cancel-appointment-confirmation')}
        onAccepted={handleCancelBooking}
        onDismiss={() => setShowModal(false)}
      />
    </View>
  );
};

export interface AppointmentProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: theme.getSpacing(32),
    paddingBottom: theme.getSpacing(32),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(32),
    gap: theme.getSpacing(16),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(24),
  },
  title: {
    fontSize: 25,
    flexGrow: 1,
  },
  backButton: {
    margin: 0,
    marginLeft: -10,
  },
}));
