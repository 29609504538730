import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Text } from '../../../../../packages/assets/components/text';
import { Icon } from '../../../../../packages/assets/components/icon';

export const FormSectionSeparator: FunctionComponent<
  FormSectionSeparatorProps
> = ({ icon, label }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.separatorContainer}>
      <Icon icon={icon} size={16} color={theme.palette.gray[700]} />
      <Text style={styles.separatorLabel}>{label}</Text>
    </View>
  );
};

interface FormSectionSeparatorProps {
  icon: FunctionComponent;
  label: string;
}

const useStyles = makeStyles((theme) => ({
  separatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(8),
  },
  separatorLabel: {
    marginLeft: theme.getSpacing(4),
    color: theme.palette.gray[700],
  },
}));
