import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { Icon } from '../../icon';
import { InfoIcon } from '../../../icons';
import { makeStyles, useTheme } from '../../../theme';
import { Text } from '../../text';

export const ToolbarContentBox: FunctionComponent<
  PropsWithChildren<ToolbarContentBoxProps>
> = ({ title, description, style, testID, flat }) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={[styles.root, !flat && styles.flat, style]} testID={testID}>
      <View>
        <Icon icon={InfoIcon} color={theme.palette.primary[700]} size={18} />
      </View>
      <View style={styles.textContainer}>
        <View>
          <Text style={styles.title}>{title}</Text>
        </View>
        {description && (
          <View style={{ marginTop: theme.getSpacing(4) }}>
            <Text style={styles.description}>{description}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(8),
    marginRight: theme.getSpacing(4),
  },
  textContainer: {
    flexDirection: 'column',
    marginLeft: theme.getSpacing(8),
    flexWrap: 'wrap',
    flex: 1,
    flexGrow: 1,
  },
  title: {
    ...theme.fonts.medium,
    color: theme.palette.primary[700],
  },
  description: {
    color: theme.palette.primary[600],
    fontSize: 14,
  },
  flat: {
    backgroundColor: theme.palette.primary[50],
    borderRadius: theme.roundness,
    padding: theme.getSpacing(16),
  },
}));

export interface ToolbarContentBoxProps {
  title?: string;
  description?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  flat?: boolean;
}
