import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { PastAppointmentsList } from '../../../schedule/appointments-past-list/AppointmentsPastList';

export const PastAppointments: FunctionComponent<PastAppointmentsProps> = (
  props,
) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <PastAppointmentsList />
      </View>
    </View>
  );
};

interface PastAppointmentsProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.getSpacing(32),
    height: '100%',
  },
  cellContainer: {
    marginTop: theme.getSpacing(8),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
