import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
} from '@ag-grid-community/core';
import {
  getSchedules,
  resetAvailabilitiesList,
  setDefaultSchedule,
  setReset,
} from './availabilities-list-actions';
import { useAvailabilitiesListState } from './availabilities-list-store';
import { DataGrid } from 'assets/components/data-grid';
import { makeStyles, useTheme } from 'assets/theme';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { AppointmentTypeScheduleDto } from '@digitalpharmacist/appointment-service-client-axios';
import { scheduleAvailabilityToString } from '../../common/datetime-utils';
import { View } from 'react-native';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { IconButton } from 'assets/components/icon-button';
import { ClockIcon, PencilIcon, TrashIcon } from 'assets/icons';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import { Text } from 'assets/components/text';
import { Tooltip } from '../../components/Tooltip';
import { TooltipWrapper } from 'react-tooltip';
import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { getText } from '../../../../../packages/assets/localization/localization';
import { deleteSchedule } from '../availability-form/availability-form-actions';

const MENU_ID = 'availabilities-row-options';

const AvailabilityHoursRenderer = (props: {
  data: AppointmentTypeScheduleDto;
}) => {
  const availabilities = props.data.availability;
  const styles = useStyles();
  const baseAvailabilities = availabilities
    .filter((availability) => !availability.date)
    .sort((a, b) => a.days[0] - b.days[0]);

  return (
    <View style={styles.cellContainer}>
      <Text>
        {baseAvailabilities?.map((availability, i) => (
          <span key={i}>
            {scheduleAvailabilityToString(availability)}
            {i + 1 !== baseAvailabilities.length && ', '}
          </span>
        ))}
      </Text>
    </View>
  );
};

export const AvailabilitiesList: FunctionComponent<AvailabilitiesListProps> = (
  props,
) => {
  const { schedules, reset } = useAvailabilitiesListState();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const theme = useTheme();
  const [hideTooltip, setHideTooltip] = useState(false);

  useFocusEffect(
    useCallback(() => {
      getSchedules();
    }, []),
  );

  useEffect(() => {
    if (reset) {
      getSchedules();
      setReset(false);
    }
  }, [reset]);

  // When you change a tooltip that should be shown while the previous one is visible
  // it glitches the previous tooltip and it can't be hidden (two tooltips are visible then).
  // This is a workaround that resets the tooltips.
  const resetTooltip = () => {
    setHideTooltip(true);
    setTimeout(() => setHideTooltip(false), 50);
  };

  const setGridApi = (event: GridReadyEvent) => {
    const { api } = event;
    event.api.setDomLayout('autoHeight');
    useAvailabilitiesListState.setState({ gridApi: api });
  };

  const ActionButtonsRenderer = (props: {
    data: AppointmentTypeScheduleDto;
  }) => {
    const rowData = props.data;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const navigation = useNavigation<ScheduleDrawerNavigationProp>();
    const styles = useStyles();

    const handleDeleteClick = async () => {
      setShowDeleteConfirmation(false);
      await deleteSchedule(rowData.id);
      resetAvailabilitiesList();
    };

    return (
      <View style={styles.cellContainer}>
        <TooltipWrapper
          tooltipId={
            rowData.isDefault ? 'default-tooltip' : 'make-default-tooltip'
          }
        >
          <ToggleSwitch
            logger={{ id: `change-status-availability--${rowData.id}` }}
            value={rowData.isDefault}
            onPress={() => {
              setDefaultSchedule(rowData);
              resetTooltip();
            }}
          />
        </TooltipWrapper>

        <TooltipWrapper tooltipId="edit-availability-record-tooltip">
          <IconButton
            icon={PencilIcon}
            logger={{ id: `edit-availability--${rowData.id}` }}
            onPress={() =>
              navigation.navigate('edit-availability', {
                availabilityId: rowData.id,
              })
            }
          />
        </TooltipWrapper>

        <TooltipWrapper
          tooltipId={
            rowData.in_use
              ? 'delete-availability-in-use-tooltip'
              : 'delete-availability-record-tooltip'
          }
        >
          <IconButton
            icon={TrashIcon}
            logger={{ id: `delete-availability--${rowData.id}` }}
            onPress={() => setShowDeleteConfirmation(true)}
            disabled={rowData.in_use}
          />
        </TooltipWrapper>
        <PharmacyConfirmationModal
          show={showDeleteConfirmation}
          onAccepted={() => handleDeleteClick()}
          onDismiss={() => setShowDeleteConfirmation(false)}
          message={getText('delete-confirmation-modal', {
            itemType: getText('availability').toLowerCase(),
            itemName: `"${rowData.title}"`,
          })}
        />
      </View>
    );
  };

  const [columnDefs] = useState([
    {
      width: 300,
      maxWidth: 300,
      field: 'title',
      headerName: 'Service',
    },
    {
      field: 'availability',
      headerName: 'Hours',
      cellRenderer: AvailabilityHoursRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
      },
    },
    {
      width: 200,
      maxWidth: 200,
      field: 'actions',
      headerName: 'Actions',
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerClass: 'data-grid-header-right-aligned',
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  return (
    <>
      <DataGrid
        gridOptions={{
          onGridReady: setGridApi,
          rowData: schedules,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: false,
          loadingOverlayComponent: 'loadingIndicator',
          loadingOverlayComponentParams: {
            color: theme.colors.pharmacyPrimary,
          },
          components: {
            loadingIndicator: LoadingIndicator,
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title="No availability"
              subtitle="Please check back later"
              icon={<ClockIcon size={100} color={theme.palette.gray[300]} />}
              layout="vertical"
            />
          ),
        }}
        gridToolbarProps={{
          titleProps: {
            title: 'Availability',
          },
          actionButtonsProps: {
            maxActionToShow: 2,
            actionButtons: [
              {
                text: 'New availability',
                hierarchy: 'pharmacy-primary',
                logger: { id: 'new-availability-button' },
                onPress: () => navigation.navigate('new-availability'),
              },
            ],
          },
        }}
      />
      {!hideTooltip && (
        <Tooltip text="Make default" id="make-default-tooltip" />
      )}
      {!hideTooltip && (
        <Tooltip text="Default availability" id="default-tooltip" />
      )}
      <Tooltip
        text={getText('edit-availability-record-tooltip')}
        id="edit-availability-record-tooltip"
      />
      <Tooltip
        text={getText('delete-availability-record-tooltip')}
        id="delete-availability-record-tooltip"
      />
      <Tooltip
        text={getText('delete-availability-in-use-tooltip')}
        id="delete-availability-in-use-tooltip"
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  loadingContainer: {
    marginTop: theme.getSpacing(32),
  },
}));

export interface AvailabilitiesListProps {}
