import React, { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import { ColDef, ColGroupDef, GridReadyEvent } from '@ag-grid-community/core';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { AppointmentTypeDto } from '@digitalpharmacist/appointment-service-client-axios';
import { TooltipWrapper } from 'react-tooltip';
import { Tooltip } from '../../components/Tooltip';
import { makeStyles, useTheme } from 'assets/theme';
import { DataGrid } from 'assets/components/data-grid';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { IconButton } from 'assets/components/icon-button';
import { ClockIcon, PencilIcon, TrashIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { CopyToClipboard } from 'assets/components/copy-to-clipboard';
import { getText } from 'assets/localization/localization';

import {
  getServices,
  deleteService,
  setServiceStatus,
} from './services-list-actions';
import { useServicesListState } from './services-list-store';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import { useToast } from '../../common/hooks/useToast';
import { EllipsisTextRenderer } from '../../components/EllipsisTextRenderer';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { getServiceUrl } from './services-list-utils';
import { useAppStateStore } from '../../store/app-store';

const ServiceLinkRenderer = (props: { data: AppointmentTypeDto }) => {
  const styles = useStyles();
  const rowData = props.data;
  const { toast } = useToast();
  const { locationId } = useAppStateStore();
  const serviceUrl = getServiceUrl(rowData.id, locationId);

  return (
    <View style={styles.cellContainer}>
      <Text style={styles.textEllipsis} selectable>
        {serviceUrl}
      </Text>
      <TooltipWrapper tooltipId={'copy-link-button-tooltip'}>
        <CopyToClipboard
          logger={{ id: `copy-form-url--${rowData.id}` }}
          stringToCopy={serviceUrl}
          fetchFromClipboard={() => toast('Copied to clipboard')}
        />
      </TooltipWrapper>
    </View>
  );
};

export const ServicesList: FunctionComponent<ServicesListProps> = (props) => {
  const theme = useTheme();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();

  const services = useServicesListState((state) => state.services);
  const gridApi = useServicesListState((state) => state.gridApi);

  const setGridApi = (event: GridReadyEvent) => {
    const { api } = event;
    event.api.setDomLayout('autoHeight');
    useServicesListState.setState({ gridApi: api });
  };

  const [hideTooltip, setHideTooltip] = useState(false);

  const resetTooltip = () => {
    setHideTooltip(true);
    setTimeout(() => setHideTooltip(false), 50);
  };

  const ActionButtonsRenderer = (props: { data: AppointmentTypeDto }) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const styles = useStyles();
    const rowData = props.data;
    const navigation = useNavigation<ScheduleDrawerNavigationProp>();

    const handleStatusToggle = () => {
      setServiceStatus(!rowData.enabled, rowData.id, rowData);
      resetTooltip();
    };

    return (
      <View style={styles.cellContainer}>
        <TooltipWrapper
          tooltipId={
            rowData.enabled
              ? 'service-record-enabled-tooltip'
              : 'service-record-disabled-tooltip'
          }
        >
          <ToggleSwitch
            logger={{ id: `change-status-service--${rowData.id}` }}
            value={rowData.enabled}
            onPress={handleStatusToggle}
          ></ToggleSwitch>
        </TooltipWrapper>
        <TooltipWrapper tooltipId="edit-service-record-tooltip">
          <IconButton
            icon={PencilIcon}
            logger={{ id: `edit-service--${rowData.id}` }}
            onPress={() =>
              navigation.navigate('edit-service', {
                serviceId: rowData.id,
              })
            }
          />
        </TooltipWrapper>
        <TooltipWrapper tooltipId="delete-service-record-tooltip">
          <IconButton
            icon={TrashIcon}
            logger={{ id: `delete-service--${rowData.id}` }}
            onPress={() => setShowDeleteConfirmation(true)}
          />
        </TooltipWrapper>
        <PharmacyConfirmationModal
          show={showDeleteConfirmation}
          onAccepted={() => {
            setShowDeleteConfirmation(false);
            deleteService(rowData.id);
          }}
          onDismiss={() => setShowDeleteConfirmation(false)}
          message={getText('delete-confirmation-modal', {
            itemType: getText('service').toLowerCase(),
            itemName: `"${rowData.title}"`,
          })}
        />
      </View>
    );
  };

  const [columnDefs] = useState([
    {
      field: 'title',
      headerName: 'Service',
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={value} />
      ),
    },
    {
      width: 120,
      field: 'length',
      headerName: 'Duration',
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={`${value} min`} />
      ),
    },
    { width: 280, cellRenderer: ServiceLinkRenderer, headerName: 'Link' },
    // TODO: This is not yet implemented on the back-end, the hard-coded value needs to be replaced with the API data.
    {
      field: 'location',
      headerName: getText('venue'),
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={getText('venue-in-person')} />
      ),
    },
    {
      width: 200,
      maxWidth: 200,
      field: 'actions',
      headerName: 'Actions',
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerClass: 'data-grid-header-right-aligned',
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  useFocusEffect(
    useCallback(() => {
      if (gridApi) {
        getServices({ withDisabled: true, withoutNextAvailableSlot: true });
      }
    }, [gridApi]),
  );

  return (
    <>
      <DataGrid
        gridOptions={{
          onGridReady: setGridApi,
          rowData: services,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: false,
          loadingOverlayComponent: 'loadingIndicator',
          loadingOverlayComponentParams: {
            color: theme.colors.pharmacyPrimary,
          },
          components: {
            loadingIndicator: LoadingIndicator,
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title="No services"
              subtitle="Please check back later"
              icon={<ClockIcon size={100} color={theme.palette.gray[300]} />}
              layout="vertical"
            />
          ),
        }}
        gridToolbarProps={{
          titleProps: {
            title: 'Services',
          },
          actionButtonsProps: {
            maxActionToShow: 2,
            actionButtons: [
              {
                text: 'New service',
                hierarchy: 'pharmacy-primary',
                logger: { id: 'new-service-button' },
                onPress: () => navigation.navigate('new-service'),
              },
            ],
          },
        }}
      />

      {!hideTooltip && (
        <Tooltip
          text={getText('service-record-enabled-tooltip')}
          id="service-record-enabled-tooltip"
        />
      )}
      {!hideTooltip && (
        <Tooltip
          text={getText('service-record-disabled-tooltip')}
          id="service-record-disabled-tooltip"
        />
      )}

      <Tooltip
        text={getText('copy-link-button-tooltip')}
        id="copy-link-button-tooltip"
      />
      <Tooltip
        text={getText('edit-service-record-tooltip')}
        id="edit-service-record-tooltip"
      />
      <Tooltip
        text={getText('delete-service-record-tooltip')}
        id="delete-service-record-tooltip"
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.getSpacing(32),
    fontSize: 20,
    marginBottom: theme.getSpacing(16),
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  statusIcon: {
    display: 'flex',
    paddingTop: 2,
    paddingHorizontal: theme.getSpacing(4),
  },
}));

export interface ServicesListProps {}
