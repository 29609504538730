import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { AlertCircleIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon/Icon';

export const AccessDenied: FunctionComponent<AccessDeniedProps> = () => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <ScreenContainer>
      <View style={styles.container}>
        <Icon
          icon={AlertCircleIcon}
          color={theme.palette.gray[400]}
          size={50}
        />
        <Text style={styles.title}>{getText('access-denied')}</Text>
        <Text style={styles.subtitle}>{getText('contact-administration')}</Text>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.gray[900],
    fontSize: 20,
    lineHeight: 30,
    paddingTop: theme.getSpacing(8),
    textAlign: 'center',
  },
  subtitle: {
    color: theme.palette.gray[700],
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    maxWidth: 180,
  },
}));

interface AccessDeniedProps {}
