import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, useTheme } from 'assets/theme';

import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { useTasksDataTableState } from './tasks-data-table-store';
import { DateTimePickerField } from '../../components/DateTimePickerField';
import { IconButton } from 'assets/components/icon-button';
import { Icon } from 'assets/components/icon';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  BookmarkIcon,
  BookmarkIconFilledIn,
  PencilIcon,
  TrashIcon,
} from 'assets/icons';
import { ColoredBadge } from '../../components/ColoredBadge';
import {
  CreateTaskDto,
  TaskPriority,
  TaskStatus,
} from '@digitalpharmacist/tasks-service-client-axios';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { formatDateTime } from '../../common/datetime-utils';
import moment from 'moment';
import { Avatar } from 'assets/components/avatar';
import { CollapsibleText } from '../../components/CollapsibleText';
import { editTask, updateTask } from './tasks-data-table-actions';
import { deleteTask } from '../task-modal/task-modal-actions';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { PharmacySidebar } from '../../components/PharmacySidebar';
import { CSSObject } from 'react-pro-sidebar';
import { visibilityFormatter } from './tasks-data-table.utils';

export const TaskDetailsSidebar: FunctionComponent<TaskDetailsSidebarProps> = ({
  sidebarRootStyle,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { gridApi, detailsStatus, taskDetails } = useTasksDataTableState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showResolveConfirmation, setShowResolveConfirmation] = useState(false);

  const methods = useForm<CreateTaskDto>({
    defaultValues: {
      due_date: new Date().toISOString(),
      status: TaskStatus.Unresolved,
    },
  });

  const isDisabled =
    taskDetails?.status == TaskStatus.Resolved || !!taskDetails?.deleted_at;

  useEffect(() => {
    methods.reset({
      due_date: taskDetails?.due_date
        ? taskDetails.due_date
        : new Date().toISOString(),
      status: taskDetails?.status ? taskDetails.status : TaskStatus.Unresolved,
    });
  }, [taskDetails]);

  // Important to unsubscribe here on return, to stop tracking form changes
  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name == 'due_date') {
        updateTask(taskDetails?.id as string, {
          due_date: value.due_date,
        });
      }

      if (name == 'status') {
        value.status === TaskStatus.Resolved
          ? setShowResolveConfirmation(true)
          : updateTask(taskDetails?.id as string, {
              status: value.status,
            });
      }
    });

    return () => subscription.unsubscribe();
  }, [taskDetails, methods.watch]);

  const MetaRow = (props: {
    label: string;
    icon?: FunctionComponent | undefined;
    color?: string;
    value: string;
  }) => {
    return (
      <View style={styles.metaRow}>
        <Text style={styles.fieldLabel} selectable>
          {props.label}
        </Text>
        <View style={styles.metaRowContent}>
          {props.icon && (
            <Icon icon={props.icon} color={props.color} size={14}></Icon>
          )}
          <Text
            style={[styles.fieldValue, !!props.color && { color: props.color }]}
            selectable
          >
            {props.value}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <PharmacySidebar
      title="Task Details"
      dataExists={!!taskDetails}
      defaultCollapsed={true}
      rootStyle={{ ...sidebarRootStyle, borderRight: 'none' }}
    >
      <View>
        {taskDetails && (
          <View style={styles.sidebarWrapper}>
            <PharmacyConfirmationModal
              show={showDeleteConfirmation}
              onAccepted={() => {
                setShowDeleteConfirmation(false);
                deleteTask(taskDetails.id);
              }}
              onDismiss={() => setShowDeleteConfirmation(false)}
              message={`Are you sure you want to delete the task: "${taskDetails.summary}"?`}
            />
            <PharmacyConfirmationModal
              show={showResolveConfirmation}
              onAccepted={() => {
                setShowResolveConfirmation(false);
                updateTask(taskDetails.id, {
                  status: TaskStatus.Resolved,
                });
              }}
              onDismiss={() => {
                setShowResolveConfirmation(false);
                methods.setValue('status', taskDetails.status);
              }}
              message={`Are you sure you want to resolve the task: "${taskDetails.summary}"?`}
            />

            <View style={styles.quickActionsHeader}>
              <View style={styles.quickActionLabels}>
                {taskDetails.due_date &&
                  moment(taskDetails.due_date).isSame(moment(), 'day') && (
                    <ColoredBadge
                      label="Today"
                      color={theme.palette.gray[700]}
                      backgroundColor={theme.palette.warning[300]}
                    />
                  )}
                {taskDetails.type && (
                  <ColoredBadge
                    label={taskDetails.type.title}
                    color={theme.palette.gray[100]}
                    backgroundColor={theme.palette.gray[600]}
                    // TODO: Revert this to using the task type colors instead of shades of gray, once the specs are cleared.
                    // label={taskDetails?.type.title}
                    // backgroundColor={taskDetails?.type.color}
                  />
                )}
              </View>
              <View style={styles.quickActionsButtons}>
                <IconButton
                  icon={TrashIcon}
                  disabled={isDisabled}
                  size={16}
                  color={theme.palette.gray[400]}
                  logger={{ id: 'task-details-sidebar--delete' }}
                  onPress={() => setShowDeleteConfirmation(true)}
                ></IconButton>
                <IconButton
                  icon={
                    taskDetails.flagged ? BookmarkIconFilledIn : BookmarkIcon
                  }
                  disabled={isDisabled}
                  size={16}
                  color={
                    taskDetails.flagged
                      ? theme.palette.warning['800']
                      : theme.palette.gray[400]
                  }
                  logger={{ id: 'task-details-sidebar--flag' }}
                  onPress={() =>
                    updateTask(taskDetails.id, {
                      flagged: !taskDetails.flagged,
                    })
                  }
                ></IconButton>
                <IconButton
                  icon={PencilIcon}
                  disabled={isDisabled}
                  size={16}
                  color={theme.palette.gray[400]}
                  logger={{ id: 'task-details-sidebar--edit' }}
                  onPress={() => editTask(taskDetails.id)}
                ></IconButton>
              </View>
            </View>
            <View style={styles.titleContainer}>
              <Text style={styles.title} selectable>
                {taskDetails.summary}
              </Text>
            </View>
            <View style={styles.principalActionsHeader}>
              <Form methods={methods}>
                <Form.Row>
                  <Form.Column>
                    <DateTimePickerField
                      name="due_date"
                      disabled={isDisabled}
                      type="date-and-time-combo"
                      minDate={new Date().toISOString()}
                    ></DateTimePickerField>
                  </Form.Column>
                  <Form.Column>
                    <DropdownSelect
                      fieldName="status"
                      disabled={isDisabled}
                      isSearchable={false}
                      options={[
                        { label: 'Unresolved', value: TaskStatus.Unresolved },
                        { label: 'Resolved', value: TaskStatus.Resolved },
                        {
                          label: 'In Progress',
                          value: TaskStatus.InProgress,
                        },
                        { label: 'On Hold', value: TaskStatus.OnHold },
                      ]}
                    />
                  </Form.Column>
                </Form.Row>
              </Form>
            </View>
            <View style={styles.assigneeContainer}>
              <View style={styles.assigneeNameContainer}>
                <Text style={styles.fieldLabel} selectable>
                  Assigned to
                </Text>
                {taskDetails.assigned_user_id ? (
                  <Text style={styles.assigneeName} selectable>
                    {taskDetails.assigned_user_first_name}{' '}
                    {taskDetails.assigned_user_last_name}
                  </Text>
                ) : (
                  <Text style={styles.assigneeUnassignedText} selectable>
                    Nobody
                  </Text>
                )}
              </View>
              <Avatar
                size={40}
                name={
                  taskDetails.assigned_user_id
                    ? `${taskDetails.assigned_user_first_name} ${taskDetails.assigned_user_last_name}`
                    : undefined
                }
              />
            </View>
            {taskDetails.description ? (
              <View style={styles.descriptionContainer}>
                <Text style={styles.fieldLabel} selectable>
                  Description
                </Text>
                <CollapsibleText text={taskDetails.description} />
              </View>
            ) : null}
            <View style={styles.metaContainer}>
              {taskDetails.created_at ? (
                <MetaRow
                  label="Created"
                  value={formatDateTime(taskDetails.created_at)}
                />
              ) : null}
              {taskDetails.updated_at
                ? taskDetails.status != TaskStatus.Resolved && (
                    <MetaRow
                      label="Updated"
                      value={formatDateTime(taskDetails.updated_at)}
                    />
                  )
                : null}
              {taskDetails.updated_at
                ? taskDetails.status === TaskStatus.Resolved && (
                    <MetaRow
                      label="Completed"
                      value={formatDateTime(taskDetails.updated_at)}
                    />
                  )
                : null}
              {taskDetails.created_by_user_id && (
                <View style={styles.metaRow}>
                  <Text style={styles.fieldLabel} selectable>
                    Created by
                  </Text>
                  <View style={styles.metaRowContent}>
                    <Avatar
                      size={24}
                      name={`${taskDetails.created_by_user_first_name} ${taskDetails.created_by_user_last_name}`}
                    />
                    <Text style={styles.fieldValue} selectable>
                      {taskDetails.created_by_user_first_name}{' '}
                      {taskDetails.created_by_user_last_name}
                    </Text>
                  </View>
                </View>
              )}
              {taskDetails.priority ? (
                <MetaRow
                  label="Priority"
                  icon={
                    taskDetails.priority === TaskPriority.High
                      ? ArrowUpCircleIcon
                      : taskDetails.priority === TaskPriority.Low
                      ? ArrowDownCircleIcon
                      : undefined
                  }
                  color={
                    taskDetails.priority === TaskPriority.High
                      ? theme.palette.error[500]
                      : theme.palette.gray[500]
                  }
                  value={
                    taskDetails.priority.charAt(0).toUpperCase() +
                    taskDetails.priority.slice(1)
                  }
                />
              ) : null}
              {taskDetails.visibility ? (
                <MetaRow
                  label="Visibility"
                  icon={
                    taskDetails.priority === TaskPriority.High
                      ? ArrowUpCircleIcon
                      : taskDetails.priority === TaskPriority.Low
                      ? ArrowDownCircleIcon
                      : undefined
                  }
                  color={theme.palette.gray[500]}
                  value={visibilityFormatter[taskDetails.visibility]}
                />
              ) : null}
            </View>
            {taskDetails.notes ? (
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeader}>
                  <Text
                    style={[styles.fieldLabel, styles.sectionTitle]}
                    selectable
                  >
                    Notes
                  </Text>
                  <Text
                    style={[styles.fieldLabel, styles.sectionNotice]}
                    selectable
                  >
                    Internal Use Only
                  </Text>
                  <IconButton
                    icon={PencilIcon}
                    size={16}
                    color={theme.palette.gray[400]}
                    logger={{ id: 'task-details-sidebar--edit-notes' }}
                    disabled={taskDetails.status == TaskStatus.Resolved}
                    onPress={() => editTask(taskDetails.id)}
                  ></IconButton>
                </View>
                <View style={[styles.sectionContent, styles.notesContent]}>
                  <Text style={[styles.fieldValue]} selectable>
                    {taskDetails.notes}
                  </Text>
                </View>
              </View>
            ) : null}
          </View>
        )}
      </View>
    </PharmacySidebar>
  );
};

interface TaskDetailsSidebarProps {
  sidebarRootStyle?: CSSObject;
}

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    height: '100%',
    padding: theme.getSpacing(8),
  },
  sidebarWrapper: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.getSpacing(8),
    marginBottom: theme.getSpacing(8),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  headerTitleText: {
    color: theme.palette.gray[400],
  },
  quickActionsHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.getSpacing(16),
  },
  quickActionLabels: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.getSpacing(8),
  },
  quickActionsButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(8),
    marginBottom: theme.getSpacing(16),
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    fontSize: 22,
    fontWeight: '400',
    lineHeight: 27,
    color: theme.palette.gray[700],
    flex: 1,
  },
  principalActionsHeader: {
    paddingBottom: theme.getSpacing(8),
    marginBottom: theme.getSpacing(8),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    zIndex: 1,
  },
  assigneeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.getSpacing(8),
    marginBottom: theme.getSpacing(24),
    alignItems: 'center',
  },
  assigneeNameContainer: {
    flex: 1,
  },
  assigneeName: {
    fontSize: 18,
    fontWeight: '600',
    color: theme.palette.gray[900],
  },
  assigneeUnassignedText: {
    color: theme.palette.gray[500],
  },
  descriptionContainer: {
    marginBottom: theme.getSpacing(16),
  },
  fieldLabel: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 21,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(8),
    minWidth: 100,
  },
  fieldValue: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 21,
    color: theme.palette.gray[500],
  },
  metaContainer: {
    marginBottom: theme.getSpacing(8),
    display: 'flex',
    flexDirection: 'column',
  },
  metaRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(16),
  },
  metaRowContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(8),
    marginBottom: theme.getSpacing(8),
  },
  sectionContainer: {
    marginTop: theme.getSpacing(16),
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.getSpacing(8),
    marginBottom: theme.getSpacing(16),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.gray[200],
    gap: theme.getSpacing(16),
  },
  sectionTitle: {
    textTransform: 'uppercase',
    flex: 1,
    marginBottom: 0,
  },
  sectionNotice: {
    fontWeight: '400',
    color: theme.palette.gray[400],
    marginBottom: 0,
  },
  sectionAction: {
    marginBottom: 0,
    color: theme.palette.primary[500],
  },
  sectionContent: {},
  notesContent: {
    backgroundColor: theme.palette.gray[100],
    borderRadius: theme.roundness,
    padding: theme.getSpacing(16),
  },
}));

export default TaskDetailsSidebar;
