import { FunctionComponent, useState, useCallback, useMemo } from 'react';
import {
  View,
  TouchableOpacity,
  Linking,
  TextInputSelectionChangeEventData,
  NativeSyntheticEvent,
} from 'react-native';
import { v4 } from 'uuid';
import {
  GiftedChat,
  GiftedChatProps,
  Actions,
  ActionsProps,
  InputToolbarProps,
  Message,
  MessageProps,
} from 'react-native-gifted-chat';
import EmojiModal from 'react-native-emoji-modal';
import * as DocumentPicker from 'expo-document-picker';
import { TextInput } from 'react-native-paper';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import FileStorageService from '../../../api/FileStorageService';
import { getText } from 'assets/localization/localization';
import { PaperClipIcon, SmileIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { UserTyping } from '../hooks/useSockets';
import {
  IDocumentModalState,
  IImageModalState,
  LoadMoreOptions,
  TypedMessage,
  IMessageExtended,
} from '../types';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { Attachments } from './Attachments';
import { ErrorStatus } from '../error-store/error-store';
import { setError } from '../error-store/error-actions';
import { useLoadingState } from '../loading-store/loading-store';
import {
  messageMock,
  LOADING_MESSAGES,
  ERROR_SELECT_FILES,
  ERROR_MAX_FILES_COUNT_TEN,
  MESSAGE_LIMIT,
  EMOJI_CHARS_LENGTH,
  TAKE,
  ATTACHMENT_SIDE_PADDING,
  BUTTON_MARGIN,
  INPUT_TOOLBAR_MARGIN,
} from '../data';
import Footer from './Footer';
import { useInboxState } from '../inbox-store/inbox-store';
import ImageModal from './ImageModal';
import ChatDocumentViewer from './ChatDocumentViewer';
import { getChatSizes, getLinesCount } from '../utils';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import Bubble from './Bubble';

export const ChatBox: FunctionComponent<ChatBoxProps> = (props) => {
  const locationId = useAppStateStore((state) => state.locationId);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const loadingObject = useLoadingState((state) => state.loadingObject);
  const { messagesPagination } = useInboxState();
  const [text, setText] = useState<string>('');
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [imageModalState, setImageModalState] = useState<IImageModalState>({
    show: false,
    uri: '',
    stored_filename: '',
  });
  const [documentModalState, setDocumentModalState] =
    useState<IDocumentModalState>({
      show: false,
      stored_filename: '',
      name: '',
    });

  const styles = useStyles();
  const theme = useTheme();

  const lastRightMessageId = useMemo(() => {
    const messages = props.messages ?? [];

    return messages.find(
      (message) => message.author_type === AuthorType.Pharmacy,
    )?._id;
  }, [props.messages]);

  const {
    wholeContainerHeight,
    messagesContainerHeight,
    textInputToolbarHeight,
  } = getChatSizes(text, attachments.length);

  const getMessages = () => {
    if (loadingObject[LOADING_MESSAGES].isLoading) {
      return [messageMock];
    }

    return props.messages;
  };
  const messages = getMessages();

  const onSelectAttachments = async () => {
    try {
      const docsResult: DocumentPicker.DocumentResult =
        await DocumentPicker.getDocumentAsync({
          multiple: true,
        });

      if (docsResult.type === 'cancel' || !docsResult.output) {
        setError(
          ERROR_SELECT_FILES,
          ErrorStatus.ERROR,
          getText('selecting-files-wrong'),
        );
        return;
      }

      const files = Object.values(docsResult.output);

      setAttachments((attachments) => {
        const totalAttachments = [...attachments, ...files];
        const uniqueNames: string[] = [];
        const uniqueAttachments = totalAttachments.filter((attachment) => {
          const isDuplicate = uniqueNames.includes(attachment.name);

          if (!isDuplicate) {
            uniqueNames.push(attachment.name);
            return true;
          }

          return false;
        });

        if (uniqueAttachments.length > 10) {
          setAttachments([]);
          setError(
            ERROR_MAX_FILES_COUNT_TEN,
            ErrorStatus.ERROR,
            getText('maxim-count-attachments', {
              count: 10,
            }),
          );
          return [...attachments];
        }

        return uniqueAttachments;
      });
    } catch (error) {
      console.error('Documents picking error: ', error);
    }
  };

  const onRemoveFile = (name: string) => {
    const filteredAttachments = attachments.filter(
      (attachment: File) => attachment.name !== name,
    );
    setAttachments(filteredAttachments);
  };

  const downloadAttachment = async (storedFilename: string) => {
    const urlResponse = await FileStorageService.readUrl(
      LocationCategory.DirectMessage,
      locationId,
      storedFilename,
      pharmacyId,
    );
    const url: string = urlResponse.data.url;
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.error(`No possibility to open url ${url}`);
    }
  };

  const renderBubble = useCallback(
    (args: any) => (
      <Bubble
        args={args}
        loading={loadingObject[LOADING_MESSAGES].isLoading}
        lastRightMessageId={lastRightMessageId}
        setDocumentModalState={setDocumentModalState}
        setImageModalState={setImageModalState}
      />
    ),
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    [loadingObject[LOADING_MESSAGES]?.isLoading, lastRightMessageId],
  );

  const onSmileClick = () => {
    setShowEmojis((previousState) => {
      return !previousState;
    });
  };

  const insertEmoji = (
    string: string,
    position: number,
    emoji: string | null,
  ) => {
    const stringLength = string.length;
    if (!position || !stringLength || stringLength === position) {
      return string + (emoji ?? '');
    }

    const firstPart = string.slice(0, position);
    const lastPart = string.slice(-(stringLength - position));
    return firstPart + (emoji ?? '') + lastPart;
  };

  const onEmojiSelect = (emoji: string | null) => {
    if (text.length <= MESSAGE_LIMIT - EMOJI_CHARS_LENGTH) {
      setText(insertEmoji(text, cursorPosition, emoji));
    }
  };

  const ref = useOutsideClick(() => {
    setShowEmojis(false);
  });

  const renderActions = useCallback((actionsProps: Readonly<ActionsProps>) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {/* TODO: This code will be implemented later.
        <Actions
          {...actionsProps}
          options={{
            [getText("text-formatting")]: notImplementedAlert,
          }}
          icon={() => (
            <AlignLeftIcon size={28} color={theme.palette.gray[500]} />
          )}
        /> */}
        <Actions
          {...actionsProps}
          icon={() => (
            <TouchableOpacity onPress={onSmileClick}>
              <SmileIcon size={28} color={theme.palette.gray[500]} />
            </TouchableOpacity>
          )}
        />
        <Actions
          {...actionsProps}
          icon={() => (
            <TouchableOpacity onPress={onSelectAttachments}>
              <PaperClipIcon size={28} color={theme.palette.gray[500]} />
            </TouchableOpacity>
          )}
        />
      </View>
    );
  }, []);

  const handleOnPress = useCallback(() => {
    const user = props.user;
    const textToSend = text.trim();

    void (async () => {
      if (user) {
        await props.onSendMessage({
          text: textToSend,
          user,
          attachments: attachments,
        });
      }
    })();

    setAttachments([]);
    setText('');
  }, [text, props.onSendMessage, attachments]);

  const onInputTextChanged = useCallback(
    (text: string) => {
      props.onTyping();
      setText(text);
    },
    [setText, props.onTyping],
  );

  const renderSend = useCallback(() => {
    return (
      <Button
        hierarchy="pharmacy-primary"
        size="small"
        logger={{ id: 'send-message' }}
        style={{ marginRight: 4, paddingBottom: BUTTON_MARGIN }}
        onPress={handleOnPress}
      >
        {getText('send')}
      </Button>
    );
  }, [handleOnPress]);

  const renderFooter = useCallback(() => {
    return (
      <Footer
        typingMember={props.typingMember}
        text={text}
        conversationId={props.conversationId}
      />
    );
  }, [props.typingMember, text]);

  const renderMessage = useCallback((props: MessageProps<IMessageExtended>) => {
    if (!props.currentMessage) {
      return null;
    }

    return (
      <Message
        {...props}
        position={
          props.currentMessage.author_type === AuthorType.Pharmacy
            ? 'right'
            : 'left'
        }
      />
    );
  }, []);

  const getLoadEarlier = (): boolean => {
    if (!messages || !messages.length) {
      return false;
    } else if (
      messages.length < props.messagesCount &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !loadingObject[LOADING_MESSAGES]?.isLoading
    ) {
      return true;
    }
    return false;
  };

  const onEndReached = async () => {
    if (messagesPagination.take >= messagesPagination.count) {
      return;
    }

    const take = messagesPagination.take + TAKE;
    const skip = 0;
    await props.loadMore({ skip, take: take });
  };

  const renderInputToolbar = useCallback(
    (inputProps: InputToolbarProps<IMessageExtended>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- destructed to get `restProps` without `containerStyle` to pass `ActionsProps` type for `renderActions(...)`
      const { containerStyle, ...restProps } = inputProps;
      return (
        <>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: theme.palette.gray[200],
            }}
          ></View>
          <View style={{ flexDirection: 'column', width: '100%' }}>
            <TextInput
              {...props.textInputProps}
              value={text}
              placeholder={props.placeholder}
              autoComplete="off"
              autoCapitalize="none"
              style={{
                height: textInputToolbarHeight,
                backgroundColor: theme.palette.white,
              }}
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              outlineColor={theme.palette.white}
              onChangeText={(typedText) => onInputTextChanged(typedText)}
              maxLength={MESSAGE_LIMIT}
              multiline={true}
              numberOfLines={getLinesCount(text)}
              onSelectionChange={(
                event: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
              ) => {
                setCursorPosition(event.nativeEvent.selection.start);
              }}
            />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: 36,
                marginBottom: INPUT_TOOLBAR_MARGIN,
              }}
            >
              {renderActions(restProps)}
              {renderSend()}
            </View>
          </View>
        </>
      );
    },
    [text, onInputTextChanged, renderActions, renderSend],
  );

  return (
    <View style={[styles.chatWrapper, { maxHeight: wholeContainerHeight }]}>
      <GiftedChat
        {...props}
        messages={messages}
        text={text}
        renderMessage={renderMessage}
        renderBubble={renderBubble}
        shouldUpdateMessage={() => true}
        messagesContainerStyle={{ height: messagesContainerHeight }}
        renderInputToolbar={renderInputToolbar}
        renderFooter={renderFooter}
        loadEarlier={getLoadEarlier()}
        listViewProps={{
          disableVirtualization: true, // TODO: might be a temporary solution, because it is a deprecated property in FlatList
          onEndReached: onEndReached,
          onEndReachedThreshold: 0.7,
        }}
      />
      {showEmojis && (
        <View style={{ position: 'relative' }}>
          <View style={styles.emojiWrapper} ref={ref as any}>
            <EmojiModal onEmojiSelected={(emoji) => onEmojiSelect(emoji)} />
          </View>
        </View>
      )}
      {Boolean(attachments.length) && (
        <View
          style={{
            padding: ATTACHMENT_SIDE_PADDING,
            borderTopWidth: 1,
            borderTopColor: theme.palette.gray[200],
          }}
        >
          <Attachments files={attachments} onRemoveFile={onRemoveFile} />
        </View>
      )}
      <ChatDocumentViewer
        chatDocumentViewerState={documentModalState}
        setChatDocumentViewerState={setDocumentModalState}
        downloadAttachment={downloadAttachment}
      />
      <ImageModal
        imageModalState={imageModalState}
        setImageModalState={setImageModalState}
        downloadAttachment={downloadAttachment}
      />
    </View>
  );
};

export interface ChatBoxProps extends GiftedChatProps {
  messages: IMessageExtended[];
  typingMember: UserTyping | null;
  messagesCount: number;
  conversationId: string;
  onTyping: () => void;
  onSendMessage: (typedMessage: TypedMessage) => Promise<void>;
  loadMore: (options: LoadMoreOptions) => Promise<void>;
}

ChatBox.defaultProps = {
  placeholder: getText('type-your-message'),
  alwaysShowSend: true,
  showUserAvatar: true,
  scrollToBottom: false,
  messageIdGenerator: () => v4(),
};

const useStyles = makeStyles((theme) => ({
  emojiWrapper: {
    position: 'absolute',
    bottom: 44,
    zIndex: 10,
    backgroundColor: 'black',
    padding: theme.getSpacing(8),
    borderRadius: 16,
  },
  image: {
    height: 100,
    width: 100,
    zIndex: 1,
  },
  imagesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fileWrapper: {
    color: theme.palette.cyan[200],
  },
  wrapper: {
    margin: theme.getSpacing(8),
  },
  chatWrapper: {
    maxHeight: 416,
    width: '100%',
    height: '100%',
  },
}));
