import GSTC from 'gantt-schedule-timeline-calendar';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFocusEffect } from '@react-navigation/native';
import shallow from 'zustand/shallow';

import { makeStyles, useTheme } from 'assets/theme';
import {
  collapseDetailsSidebar,
  getFindOptions,
  getTasksGrid,
  setGstc,
  setGstcState,
  setIconColors,
  setMaxDueDate,
  setMinDueDate,
  setReloadTasksGrid,
} from './tasks-grid-actions';
import { useTasksGridState } from './tasks-grid-store';
import { View } from 'react-native';
import { Text } from 'assets/components/text';

import 'gantt-schedule-timeline-calendar/dist/style.css';
import './tasks-grid.css';
import { generateItems, generateRows, getConfig } from './tasks-grid-utils';
import TaskContextMenu from '../tasks-data-table/TaskContextMenu';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { setContextMenuTask } from '../tasks-data-table/tasks-data-table-actions';
import { TaskDto } from '@digitalpharmacist/tasks-service-client-axios';
import { TextInput } from 'react-native-paper';
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseCircleIcon,
  RefreshCWIcon,
  SearchIcon,
} from 'assets/icons';
import TaskDetailsSidebar from '../tasks-data-table/TaskDetailsSidebar';
import { useProSidebar } from 'react-pro-sidebar';
import { useTasksDataTableState } from '../tasks-data-table/tasks-data-table-store';
import { TasksSortingContextMenu } from './TasksSortingContextMenu';
import { useTasksFiltersState } from '../tasks-filters/tasks-filters-store';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import NoTasks from '../tasks-data-table/NoTasks';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { composeFiltersMessage } from '../tasks-data-table/tasks-data-table.utils';
import useDebounceSearchTerm from '../tasks-filters/useDebounceSearchTerm';
import { ToolbarContentBox } from '../../../../../packages/assets/components/data-grid/data-grid-toolbar/ToolbarContentBox';
import { Button } from '../../../../../packages/assets/components/button';
import moment from 'moment';
import { useTaskModalState } from '../task-modal/task-modal-store';
import { getText } from '../../../../../packages/assets/localization/localization';
import { resetFilters } from '../tasks-filters/tasks-filters-actions';

const MENU_ID = 'row-options';
const SORTING_MENU_ID = 'sorting';

export const TasksGrid: FunctionComponent = () => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    tasks,
    status,
    sidebarCollapsed,
    reload,
    sortingMenuOpen,
    sortBy,
    order,
    incomingTasks,
    infiniteScrollStatus,
    gstcState,
    minDueDate,
    maxDueDate,
  } = useTasksGridState();
  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const [gridContainerRef, setGridContainerRef] =
    useState<HTMLDivElement | null>(null);

  const { show } = useContextMenu({
    id: MENU_ID,
  });
  const { show: showSortingMenu } = useContextMenu({
    id: SORTING_MENU_ID,
  });

  const { collapseSidebar, collapsed } = useProSidebar();

  const dataTableTaskStatus = useTasksDataTableState(
    (state) => state.taskStatus,
  );
  const taskStatus = useTaskModalState((state) => state.taskStatus);

  const { filters, activeTab, isCustomFilter } = useTasksFiltersState(
    (state) => ({
      filters: state.filters,
      isCustomFilter: state.isCustomFilter,
      activeTab: state.activeTab,
    }),
    shallow,
  );

  const [searchValue, setSearchValue] = useDebounceSearchTerm();

  const isLoading =
    status === 'loading' ||
    dataTableTaskStatus === 'loading' ||
    taskStatus === 'loading';

  const noFilterSelected = () =>
    activeTab === 'all_tasks' &&
    Object.keys(filters).length <= 2 &&
    !filters.search_term &&
    minDueDate.isSame(moment().subtract(1, 'weeks').day(1), 'day');

  useFocusEffect(
    useCallback(() => {
      getTasks();
    }, [sortBy, order, filters]),
  );

  useFocusEffect(
    useCallback(() => {
      setSearchValue('');
    }, []),
  );

  useEffect(() => {
    if (reload) {
      getTasks();
      setReloadTasksGrid(false);
      collapseSidebar(true);
    }
  }, [reload]);

  useEffect(() => {
    if (tasks && gridRef && gridContainerRef) {
      const gridCopy = gridRef.cloneNode(true) as unknown as HTMLDivElement;
      const state = GSTC.api.stateFromConfig(
        getConfig(
          tasks,
          handleDotsClick,
          minDueDate.valueOf(),
          maxDueDate.valueOf(),
        ),
      );

      const gstcResult = GSTC({
        element: gridCopy,
        state,
      });

      gridContainerRef.innerHTML = '';
      gridContainerRef.appendChild(gridCopy);
      setGridRef(gridCopy);
      setGstc(gstcResult);
      setGstcState(state);
    }
  }, [tasks, gridContainerRef]);

  useEffect(() => {
    if (!sidebarCollapsed) {
      collapseSidebar(false);
    }
  }, [sidebarCollapsed]);

  useEffect(() => {
    if (collapsed) {
      collapseDetailsSidebar(true);
    }
  }, [collapsed]);

  useEffect(() => {
    if (sortingMenuOpen) {
      showSortingMenu({ event: {} as TriggerEvent });
    }
  }, [sortingMenuOpen]);

  useFocusEffect(
    useCallback(() => {
      setIconColors({
        on_hold: theme.palette.primary[500],
        in_progress: theme.palette.warning[400],
        resolved: theme.palette.success[500],
        overdue: theme.palette.error[500],
        unresolved: theme.palette.gray[300],
        coming_soon: theme.palette.gray[300],
      });

      if (infiniteScrollStatus === 'success' && incomingTasks.length) {
        const currentRows = gstcState.data.config.list.rows;
        const currentItems = gstcState.data.config.chart.items;
        const newRows = generateRows(incomingTasks, handleDotsClick);
        const newItems = generateItems(incomingTasks);

        gstcState.update('config.list.rows', () => {
          return {
            ...currentRows,
            ...newRows,
          };
        });

        gstcState.update('config.chart.items', () => {
          return {
            ...currentItems,
            ...newItems,
          };
        });
      }
    }, [incomingTasks]),
  );

  const handleDotsClick = (task: TaskDto, event: MouseEvent) => {
    setContextMenuTask(task);

    show({ event: event });
  };

  const handlePrevWeekClick = () => {
    setMinDueDate(minDueDate.subtract(1, 'weeks'));
    setMaxDueDate(maxDueDate.subtract(1, 'weeks'));
    getTasksGrid(getFindOptions());
  };

  const handleCurrentWeekClick = () => {
    setMinDueDate(moment().subtract(1, 'weeks').day(1));
    setMaxDueDate(moment().add(1, 'weeks').day(7));
    getTasksGrid(getFindOptions());
  };

  const handleNextWeekClick = () => {
    setMinDueDate(minDueDate.add(1, 'weeks'));
    setMaxDueDate(maxDueDate.add(1, 'weeks'));
    getTasksGrid(getFindOptions());
  };

  const handleResetFiltersClick = () => {
    resetFilters('grid');
    setSearchValue('');
    handleCurrentWeekClick();
  };

  const getTasks = () => getTasksGrid(getFindOptions());

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.titleContainer,
          isCustomFilter && { marginBottom: theme.getSpacing(24) },
        ]}
      >
        <Text style={styles.title}>Tasks Grid</Text>
        <View>
          <TextInput
            placeholder="Search"
            autoComplete="off"
            autoCapitalize="none"
            style={{
              height: 44,
              width: 320,
              backgroundColor: '#EAF1F4',
            }}
            mode="outlined"
            outlineColor={theme.palette.white}
            activeOutlineColor={theme.colors.primary}
            value={searchValue ?? ''}
            left={
              !searchValue ? (
                <TextInput.Icon
                  name={SearchIcon}
                  color={theme.palette.gray[500]}
                  size={22}
                  forceTextInputFocus={false}
                  style={{ top: 4 }}
                />
              ) : null
            }
            right={
              searchValue ? (
                <TextInput.Icon
                  name={CloseCircleIcon}
                  color={theme.palette.gray[500]}
                  size={20}
                  forceTextInputFocus={false}
                  style={{ top: 4 }}
                  onPress={() => setSearchValue('')}
                />
              ) : null
            }
            onChangeText={setSearchValue}
            children={undefined}
          />
        </View>
        <View style={styles.weekButtonsContainer}>
          <View style={{ flexDirection: 'row' }}>
            <Button
              hierarchy="secondary-gray"
              onPress={handlePrevWeekClick}
              logger={{ id: 'tasks-grid-previous-week' }}
              icon={ChevronLeftIcon}
              style={styles.weekButton}
            >
              {getText('previous-week')}
            </Button>
            <Button
              hierarchy="secondary-gray"
              onPress={handleCurrentWeekClick}
              logger={{ id: 'tasks-grid-current-week' }}
              style={styles.weekButton}
            >
              {getText('current-week')}
            </Button>
            <Button
              hierarchy="secondary-gray"
              onPress={handleNextWeekClick}
              logger={{ id: 'tasks-grid-next-week' }}
              icon={ChevronRightIcon}
              iconPosition="right"
            >
              {getText('next-week')}
            </Button>
          </View>
        </View>
      </View>

      <View style={styles.sidebarContainer}>
        <TaskDetailsSidebar />
      </View>
      <View>
        <TaskContextMenu menuId={MENU_ID}></TaskContextMenu>
        {isCustomFilter && (
          <ToolbarContentBox
            title="Displaying tasks that match the following criteria:"
            description={composeFiltersMessage()}
            style={{ marginBottom: theme.getSpacing(24) }}
          />
        )}
        <TasksSortingContextMenu menuId={SORTING_MENU_ID} />
        <View style={[styles.gridContainer]}>
          <div ref={(ref) => setGridContainerRef(ref)}>
            <div ref={(ref) => setGridRef(ref)}></div>
          </div>
          {isLoading && (
            <View style={styles.infiniteLoadingIndicatorMask}></View>
          )}
          {(infiniteScrollStatus === 'loading' || isLoading) && (
            <View style={styles.infiniteLoadingIndicator}>
              <LoadingIndicator color={theme.colors.pharmacyPrimary} />
            </View>
          )}
          {!tasks?.length && !isLoading ? (
            <View style={styles.noResultsContainer}>
              {noFilterSelected() ? (
                <NoTasks />
              ) : (
                <>
                  <NoResultsOverlay
                    title={getText('tasks-grid-no-results-title')}
                    subtitle={getText('tasks-grid-no-results-subtitle')}
                    icon={
                      <CheckCircleIcon
                        size={100}
                        color={theme.palette.gray[300]}
                      />
                    }
                    addMargin={true}
                    additionalContent={
                      <View style={styles.resetFiltersButtonContainer}>
                        <Button
                          hierarchy="pharmacy-primary"
                          onPress={handleResetFiltersClick}
                          logger={{ id: 'tasks-grid-next-week' }}
                          icon={RefreshCWIcon}
                        >
                          {getText('reset-filters')}
                        </Button>
                      </View>
                    }
                  />
                </>
              )}
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(32),
  },
  titleContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(32),
    alignItems: 'center',
  },
  title: {
    fontSize: 25,
    marginRight: theme.getSpacing(24),
    alignItems: 'center',
    display: 'flex',
  },
  gridContainer: {
    position: 'relative',
    minHeight: 200,
  },
  sidebarContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    zIndex: 1,
  },
  loadingContainer: {
    marginTop: theme.getSpacing(32),
    height: 300,
  },
  noResultsContainer: {
    marginTop: theme.getSpacing(32),
    position: 'absolute',
    top: 100,
    left: '50%',
    transform: [{ translateX: '-50%' as any as number }],
    backgroundColor: theme.palette.white,
  },
  infiniteLoadingIndicatorMask: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.white,
    opacity: 0.6,
  },
  infiniteLoadingIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [
      { translateX: '-50%' as any as number },
      { translateY: '-50%' as any as number },
    ],
  },
  weekButtonsContainer: {
    paddingTop: theme.getSpacing(8),
    marginLeft: theme.getSpacing(24),
  },
  weekButton: {
    marginRight: theme.getSpacing(8),
  },
  resetFiltersButtonContainer: {
    flex: 1,
    alignItems: 'flex-start',
    marginTop: theme.getSpacing(16),
  },
}));
