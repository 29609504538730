import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { format, intervalToDuration } from 'date-fns';
import { CopyToClipboard } from 'assets/components/copy-to-clipboard/CopyToClipboard';
import { Icon } from 'assets/components/icon';
import {
  ArrowLeftIcon,
  ClockIcon,
  EmptyCircleIcon,
  EyeIcon,
  FileIcon,
  MedicationIcon,
  ShareIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { Separator } from 'react-contexify';
import { Text, TouchableOpacity, View } from 'react-native';
import VideoService, { VideoResponseModel } from '../../api/VideoService';
import { useToast } from '../../common/hooks/useToast';
import {
  VideoDetailScreenRouteProp,
  VideosDrawerNavigationParamList,
} from '../../layout/VideosDrawer';
import { LoadingIndicator } from 'assets/components/loading-indicator/LoadingIndicator';
import { useAppStateStore } from '../../store/app-store';
import { PATIENT_BASE_URL } from '../../common/constants';
import { VUCA_API_BASE_URL } from '../../common/constants';

const VideoDetails: FunctionComponent<VideoDetailScreenRouteProp> = ({
  route,
  navigation,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { toast } = useToast();
  const [videoItem, setVideoItem] = useState<VideoResponseModel>();
  const [videoDuration, setVideoDuration] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const locale = route.params.locale;
  const { pharmacyId } = useAppStateStore();
  /* TODO use URL shortening service */
  const shareURL = `https://${pharmacyId}.${PATIENT_BASE_URL}/videos?id-${route.params.video_id}`;

  const onSuccess = (success: any) => {
    const myPlayer = success.ref;
    myPlayer.one('loadstart', () => {
      const duration = intervalToDuration({
        start: 0,
        end: myPlayer.mediainfo.duration * 1000,
      });
      const { hours, minutes, seconds: secs } = duration;
      const formattedTime = format(
        new Date(0, 0, 0, hours, minutes, secs),
        `${hours ? 'H:' : ''}m:ss`,
      );
      setVideoDuration(formattedTime);
    });
  };

  useEffect(() => {
    fetchVideoItem();
  }, [route.params.video_id, locale]);

  const fetchVideoItem = async () => {
    try {
      setIsLoading(true);
      setVideoItem(undefined);
      const video: VideoResponseModel = await VideoService.getVideoInfo(
        route.params.video_id,
        locale,
      );

      setIsLoading(false);
      setVideoItem(video);
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    }
  };

  const backToVideos = () => {
    navigation.navigate(
      route.params.collection_page as keyof VideosDrawerNavigationParamList,
    );
  };

  const changeLanguage = () => {
    navigation.navigate('detail' as keyof VideosDrawerNavigationParamList, {
      video_id: route.params.video_id,
      collection_page: route.params
        .collection_page as keyof VideosDrawerNavigationParamList,
      locale: locale === 'es-US' ? 'en-US' : 'es-US',
    });
  };

  const onFailure = (data: any) => {
    console.log('Brightcove player ERROR', data);
  };

  return (
    <>
      {videoItem ? (
        <View style={styles.videoDetailsView}>
          <View style={styles.rowView}>
            <TouchableOpacity
              style={[styles.topBarButton, styles.borderRight]}
              onPress={backToVideos}
            >
              <Icon icon={ArrowLeftIcon} color="#374D58" size={16} />
              <Text style={styles.topBarText}>{getText('videos')}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.topBarButton} onPress={() => {}}>
              <Icon icon={ShareIcon} color="#374D58" size={16} />
              <Text style={styles.topBarText}>{getText('videos-share')}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.topBarButton}
              onPress={changeLanguage}
              disabled={isLoading}
            >
              <Icon icon={EyeIcon} color="#374D58" size={16} />
              <Text style={styles.topBarText}>
                {locale === 'es-US'
                  ? getText('videos-language-english')
                  : getText('videos-language-spanish')}
              </Text>
            </TouchableOpacity>
          </View>

          <View style={styles.videoTitle}>
            <Text style={styles.videoTitleText}>{videoItem.name}</Text>
          </View>

          <Separator />

          <View style={styles.videoPlayer}>
            {!isLoading && videoItem.value.video_id && (
              <ReactPlayerLoader
                accountId={videoItem.value.account_id}
                videoId={videoItem.value.video_id}
                playerId={videoItem.value.player_id}
                onSuccess={onSuccess}
                onFailure={onFailure}
                embedOptions={{ responsive: true }}
                manualReloadFromPropChanges="true"
                options={{
                  autoplay: false,
                  controls: false,
                  preload: 'metadata',
                  fluid: true,
                  playsinline: true,
                }}
              />
            )}
          </View>

          <View style={[styles.rowView, styles.videoDetails]}>
            <View style={{ width: 167 }}>
              <Text style={styles.videoDetailsTitle}>
                {getText('videos-category')}
              </Text>
              <View style={styles.rowView}>
                <Icon icon={MedicationIcon} size={16} color="#748995" />
                <Text
                  style={[
                    styles.videoDetailsValue,
                    { marginLeft: theme.getSpacing(8) },
                  ]}
                >
                  {videoItem.category.label}
                </Text>
              </View>
            </View>

            <View style={{ width: 118 }}>
              <Text style={styles.videoDetailsTitle}>
                {getText('videos-duration')}
              </Text>
              <View style={styles.rowView}>
                <Icon icon={ClockIcon} size={16} color="#748995" />
                <Text
                  style={[
                    styles.videoDetailsValue,
                    { marginLeft: theme.getSpacing(8) },
                  ]}
                >
                  {videoDuration}
                </Text>
              </View>
            </View>

            <View style={{ paddingLeft: theme.getSpacing(32) }}>
              <Text style={styles.videoDetailsTitle}>
                {getText('videos-url')}
              </Text>
              <View style={styles.rowView}>
                <TouchableOpacity>
                  <Text style={styles.videoDetailsValue}>{shareURL}</Text>
                </TouchableOpacity>
                <View style={styles.clipboard}>
                  <CopyToClipboard
                    color="#748995"
                    fetchFromClipboard={() =>
                      toast(getText('copied-to-clipboard'))
                    }
                    logger={{
                      id: 'copy-to-clipboard-video-url',
                    }}
                    stringToCopy={shareURL}
                    size={16}
                  />
                </View>
              </View>
            </View>
          </View>

          {videoItem.medication_guides.length > 0 && (
            <View style={{ marginTop: theme.getSpacing(24) }}>
              <Text
                style={[
                  styles.videoDetailsTitle,
                  { paddingBottom: theme.getSpacing(8) },
                ]}
              >
                {getText('videos-med-guides')}
              </Text>
              {/* TODO PDFs need to be opened in a browser view instead of being downloaded */}
              {Object.entries(videoItem.medication_guides_pdf_download_uri).map(
                ([code, uri]) => (
                  <View style={styles.rowView}>
                    <a href={`${VUCA_API_BASE_URL}${uri}`}>
                      <Icon icon={FileIcon} size={16} color="#0093E8" />
                      <Text
                        style={styles.medGuidesText}
                      >{`${videoItem.medication_guides_title} (${code})`}</Text>
                    </a>
                  </View>
                ),
              )}
            </View>
          )}

          <View style={{ marginTop: theme.getSpacing(24) }}>
            <Text
              style={[
                styles.videoDetailsTitle,
                { paddingBottom: theme.getSpacing(8) },
              ]}
            >
              {getText('videos-transcript')}
            </Text>
            <Text style={styles.transcriptValue}>
              {videoItem.value.transcript}
            </Text>
          </View>
        </View>
      ) : isLoading ? (
        <View style={styles.center}>
          <LoadingIndicator size={75} />
        </View>
      ) : (
        <View style={styles.center}>
          <Icon icon={EmptyCircleIcon} size={75} />
        </View>
      )}
    </>
  );
};

export default VideoDetails;

const useStyles = makeStyles((theme) => ({
  videoDetailsView: {
    marginLeft: theme.getSpacing(24),
    marginTop: theme.getSpacing(16),
  },
  rowView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  topBarButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingRight: theme.getSpacing(24),
    marginRight: theme.getSpacing(24),
  },
  topBarText: {
    color: '#374D58',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 26,
    marginLeft: theme.getSpacing(8),
  },
  borderRight: {
    borderRightColor: '#BCC8CE',
  },
  videoTitle: {
    marginTop: theme.getSpacing(16),
  },
  videoTitleText: {
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 26,
    color: '#101828',
  },
  videoPlayer: {
    width: '80%',
  },
  videoDetailsTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: '#101828',
    lineHeight: 18,
  },
  videoDetails: {
    marginTop: theme.getSpacing(8),
  },
  videoDetailsValue: {
    fontSize: 16,
    fontWeight: '400',
    color: '#374D58',
    lineHeight: 24,
  },
  medGuidesText: {
    fontSize: 16,
    fontWeight: '400',
    color: '#0093E8',
    lineHeight: 24,
    marginLeft: theme.getSpacing(8),
  },
  transcriptValue: {
    fontSize: 16,
    fontWeight: '400',
    color: '#374D58',
    lineHeight: 24,
  },
  center: {
    paddingTop: '30%',
  },
  clipboard: {
    height: 16,
    marginTop: -16,
  },
}));
