import React, { useEffect, Fragment, useMemo, useCallback } from 'react';
import { View, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TextInput } from 'react-native-paper';
import { v4 } from 'uuid';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import {
  MessageCircleIcon,
  ArrowUpFromLineIcon,
  PlusInCircleIcon,
  SearchIcon,
  PencilIcon,
} from 'assets/icons';
import unifiedCommsService from '../../api/UnifiedCommsService';
import FileStorageService from '../../api/FileStorageService';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { Avatar } from 'assets/components/avatar';
import { ToolbarSortingDropDown } from 'assets/components/data-grid/data-grid-toolbar';
import { useAppStateStore } from '../../store/app-store';
import { notImplementedAlert } from 'assets/utils/alert';
import { PatientConversation } from './components/PatientConversation';
import { ConversationBox } from './components/ConversationBox';
import {
  DirectMessagePatientDto,
  AuthorType,
  MessageTemplateDto,
  UpdatePatientWithConversationsDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { StorageKeys } from '../../../enums/storage-keys';
import { User } from 'react-native-gifted-chat';
import {
  getFullName,
  compare,
  groupConversationsByPatient,
  composeAttachments,
} from './utils';
import PatientService from '../../api/PatientService';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import { formatDate } from '../../common/datetime-utils';
import {
  sortingOptions,
  MESSAGE_LIMIT,
  CONVERSATIONS_TABS_WIDTH,
  ERROR_NO_MESSAGE,
  ERROR_MESSAGE_LIMIT_FIVE_THOUSAND,
  ERROR_FILES_SENDING,
  ERROR_FILES_SENDING_MODAL,
  LOADING_CONVERSATION_CREATING,
  EXCLUDED_ERRORS_INBOX,
  LOADING_MESSAGES,
  TAKE,
} from './data';
import {
  NewChatModal,
  CreateConversationData,
} from './components/NewChatModal';
import {
  Order,
  DirectMessageExtended,
  IMessageExtended,
  IUploadFilesResult,
  BaseFilterValues,
  MultiFilterValues,
  TypedMessage,
  GetMessagesResult,
  LoadMoreOptions,
  IConversationsSorting,
} from './types';
import { useUserState } from '../../store/user-store';
import { useInboxState } from './inbox-store/inbox-store';
import {
  setPatients,
  setSelectedPatient,
  setSelectedConversation,
  setViewedConversations,
  setConversationsSorting,
  setMessagesGroups,
  setSelectedMessages,
  setRawConversations,
  removeSelectedPatient,
  removeSelectedConversation,
  removeSelectedMessages,
  openNewChatModal,
  closeNewChatModal,
  setTemplates,
  setMessagesPagination,
  setShowEditModal,
  setShowAddPatientModal,
  setPharmacyHasPatientRecords,
  setRawConversationsCount,
  setTextFilters,
} from './inbox-store/inbox-actions';
import { useSockets } from './hooks/useSockets';
import { setInputFieldsData } from './new-chat-modal-store/new-chat-modal-actions';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useErrorState } from './error-store/error-store';
import { ErrorsContainer } from './components/ErrorsContainer';
import { setLoading } from './loading-store/loading-actions';
import { useLoadingState } from './loading-store/loading-store';
import { ErrorStatus } from './error-store/error-store';
import { setError } from './error-store/error-actions';
import VerificationAlert from './components/VerificationAlert';
import AddPatientModal from '../../components/AddPatientModal/AddPatientModal';
import EditPatientModal from '../../components/EditPatientModal/EditPatientModal';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { PatientRecords } from '../patients/patient-types';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { isDateOfBirth } from '@digitalpharmacist/validation-dp';

export default function Inbox() {
  const { locationId, pharmacyId } = useAppStateStore();
  const {
    patients,
    selectedPatient,
    selectedConversation,
    viewedConversations,
    conversationsSorting,
    messagesGroups,
    selectedMessages,
    rawConversations,
    rawConversationsCount,
    isOpenNewChatModal,
    templates,
    baseFilter,
    multiFilters,
    textFilters,
    pharmacyHasPatientRecords,
    messagesPagination,
  } = useInboxState();
  const showAddPatientModal = useInboxState(
    (state) => state.showAddPatientModal,
  );
  const showEditModal = useInboxState((state) => state.showEditModal);
  const userData = useUserState();
  const { errorObject } = useErrorState();
  const { loadingObject } = useLoadingState();

  const theme = useTheme();
  const styles = useStyles();

  const { typingMember = null, onType } = useSockets();

  if (!userData.data || !userData.data.id) {
    throw new Error('Unable to get user id');
  }

  const userId = userData.data.id;

  const user: User = useMemo(() => {
    return {
      _id: userId,
      name: getFullName(userData.data),
      avatar: function () {
        return (
          <Avatar
            size={32}
            name={getFullName(userData.data)}
            color={theme.palette.gray[100]}
          />
        );
      },
    };
  }, [userId]);

  useEffect(() => {
    void (async () => {
      if (locationId) {
        const records =
          await PatientService.findAllLocationsRecordsById(locationId);

        setPharmacyHasPatientRecords(Boolean(records.length));
      }
    })();
  }, [locationId]);

  useEffect(() => {
    void (async () => {
      const userId = await AsyncStorage.getItem(StorageKeys.UserId);

      if (userId) {
        const filterCriteria = getFilterCriteria();
        const filteredConversations =
          await unifiedCommsService.getAllFilteredConversations(
            pharmacyId,
            locationId,
            filterCriteria,
          );
        const groupedConversations = groupConversationsByPatient(
          filteredConversations,
        ).sort((currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            conversationsSorting.field,
            conversationsSorting.order,
            conversationsSorting.isDate,
          ),
        );
        removeSelectedPatient();
        removeSelectedConversation();
        setRawConversations(filteredConversations);
        setPatients(groupedConversations);
        setInitialConversationCount(filteredConversations.length);
      }
    })();
  }, [baseFilter, multiFilters, textFilters]);

  useEffect(() => {
    (() => {
      const groupedConversations = [...patients].sort(
        (currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            conversationsSorting.field,
            conversationsSorting.order,
            conversationsSorting.isDate,
          ),
      );
      setPatients(groupedConversations);
    })();
  }, [conversationsSorting]);

  useEffect(() => {
    void (async () => {
      const templates: MessageTemplateDto[] =
        await unifiedCommsService.messageTemplateFindAll(locationId);
      setTemplates(templates);
    })();
  }, [locationId]);

  function getFilterCriteria() {
    return {
      unread_by_pharmacy: baseFilter === BaseFilterValues.UNREAD,
      unread_by_patient: baseFilter === BaseFilterValues.UNREAD_BY_PATIENT,
      includes_current_pharmacy_user: multiFilters.includes(
        MultiFilterValues.INCLUDES_ME,
      )
        ? userId
        : undefined,
      created_by_current_pharmacy_user: multiFilters.includes(
        MultiFilterValues.CREATED_BY_ME,
      )
        ? userId
        : undefined,
      is_most_recent_message_less_than_one_day: multiFilters.includes(
        MultiFilterValues.TODAY,
      ),
      is_most_recent_message_less_than_seven_days: multiFilters.includes(
        MultiFilterValues.LAST_SEVEN_DAYS,
      ),
      full_name: textFilters.full_name,
      date_of_birth: textFilters.date_of_birth,
    };
  }

  function setInitialConversationCount(count: number) {
    if (rawConversationsCount === 0 && count !== 0) {
      setRawConversationsCount(count);
    }
  }

  async function uploadFiles(
    locationId: string,
    pharmacyId: string,
    files: File[] | undefined,
  ): Promise<IUploadFilesResult> {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (!files || !files.length) {
      return result;
    }

    for (const file of files) {
      try {
        const newName = v4();
        const extension = FileStorageService.getFileExtension(file.name);
        const fileName = `${newName}.${extension}`;

        const responseWriteUrl = await FileStorageService.writeUrl(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
        );

        await FileStorageService.uploadFile(file, responseWriteUrl.data.url);

        result.filesData.push({
          name: file.name,
          stored_filename: fileName,
        });
      } catch (error) {
        console.error(`Error uploading file ${file.name}. Error: `, error);
        result.isError = true;
      }
    }

    return result;
  }

  function validateMessage(message: TypedMessage) {
    let errorsCount = 0;

    if (!message.text && !message.attachments.length) {
      setError(
        ERROR_NO_MESSAGE,
        ErrorStatus.ERROR,
        getText('message-or-attachment-is-required'),
      );
      errorsCount += 1;
    }
    if (message.text && message.text.length > MESSAGE_LIMIT) {
      setError(
        ERROR_MESSAGE_LIMIT_FIVE_THOUSAND,
        ErrorStatus.ERROR,
        getText('message-characters-limit-five-thousand'),
      );
      errorsCount += 1;
    }

    return errorsCount;
  }

  async function getMessages(
    locationPatientId: string,
    conversationId: string,
    skip?: number,
    take?: number,
  ): Promise<GetMessagesResult> {
    const IMessages: IMessageExtended[] = [];

    const data = await Promise.all([
      unifiedCommsService.getAllMessagesByConversation(
        pharmacyId,
        locationId,
        locationPatientId,
        conversationId,
        skip,
        take,
      ),
      unifiedCommsService.getConversation(
        pharmacyId,
        locationId,
        locationPatientId,
        conversationId,
      ),
    ]);

    const messages = data[0].messages as DirectMessageExtended[];
    const messagesCount: number = data[0].count;
    const conversation: DirectMessagePatientDto = data[1];
    const authors = conversation.all_authors
      ? conversation.all_authors
          .filter((author) => Boolean(author))
          .reduce(
            (accumulator: any, author: any) => ({
              ...accumulator,
              [author._id]: getFullName(author),
            }),
            {},
          )
      : {};

    for await (const message of messages) {
      const isOwner = user._id === message.author_id;
      const companionName: string = authors[message.author_id];
      const attachments = message.attachments;
      const composedAttachments = await composeAttachments(
        attachments,
        locationId,
        pharmacyId,
      );

      IMessages.push({
        _id: message.id,
        user: isOwner
          ? user
          : {
              _id: message.author_id,
              avatar: function () {
                return (
                  <Avatar
                    size={32}
                    name={companionName}
                    color={theme.palette.gray[100]}
                  />
                );
              },
              name: companionName,
            },
        text: message.content || '',
        createdAt: new Date(message.created_at),
        attachments: composedAttachments,
        author_type: message.author_type,
      });
    }

    return {
      messages: IMessages,
      count: messagesCount,
    };
  }

  async function updateConversationStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
    pharmacyViewedAllMessages: boolean,
  ) {
    await unifiedCommsService.updateUserViewedStatus(
      pharmacyId,
      locationId,
      locationPatientId,
      conversationId,
      {
        patient_viewed_all_messages: patientViewedAllMessages,
        pharmacy_viewed_all_messages: pharmacyViewedAllMessages,
      },
    );
    const unreadConversationsCopy = [...viewedConversations];
    const index = unreadConversationsCopy.indexOf(conversationId);

    if (pharmacyViewedAllMessages && index === -1) {
      setViewedConversations([...unreadConversationsCopy, conversationId]);
    } else if (!pharmacyViewedAllMessages && index > -1) {
      unreadConversationsCopy.splice(index, 1);
      setViewedConversations(unreadConversationsCopy);
    }

    const changedRawList = rawConversations.map((rawConversation) => {
      if (rawConversation.conversation_id === conversationId) {
        rawConversation.pharmacy_viewed_all_messages =
          pharmacyViewedAllMessages;
      }
      return rawConversation;
    });

    const groupedConversations = groupConversationsByPatient(
      changedRawList,
    ).sort((currentConversation, nextConversation) =>
      compare(
        currentConversation,
        nextConversation,
        conversationsSorting.field,
        conversationsSorting.order,
        conversationsSorting.isDate,
      ),
    );
    setRawConversations(changedRawList);
    setPatients(groupedConversations);
  }

  async function onSelectPatient(
    patientConversationMeta: DirectMessagePatientDto,
  ) {
    const newSelectedPatient =
      selectedPatient?.id === patientConversationMeta.location_patient_id
        ? undefined
        : patientConversationMeta;

    if (selectedMessages.length) {
      removeSelectedMessages();
    }

    if (!newSelectedPatient) {
      removeSelectedPatient();
      removeSelectedConversation();
      return;
    }

    const selectedPatientLocationRecord =
      await PatientService.getPatientLocationRecord(
        locationId,
        newSelectedPatient.location_patient_id,
      );

    const conversations = rawConversations
      .filter((conversation) => {
        if (
          conversation.location_id === locationId &&
          conversation.location_patient_id ===
            newSelectedPatient.location_patient_id
        ) {
          return true;
        } else {
          return false;
        }
      })
      .sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          'most_recent_qualifying_message_date',
          Order.DESC,
          true,
        ),
      );

    const firstConversation = conversations[0];

    addViewed(conversations);
    setSelectedPatient(selectedPatientLocationRecord);
    setMessagesGroups(conversations);

    await onSelectConversation(firstConversation);
  }

  const onSend = async (typedMessage: TypedMessage) => {
    if (!selectedPatient || !user || !selectedConversation) {
      return;
    }

    const errorsCount = validateMessage(typedMessage);
    if (errorsCount) {
      return;
    }

    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      typedMessage.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      return;
    }

    const messageFromServer = await unifiedCommsService.addMessage(
      pharmacyId,
      locationId,
      selectedPatient.id,
      selectedConversation.conversation_id,
      {
        author_id: user._id.toString(),
        author_type: AuthorType.Pharmacy,
        content: typedMessage.text,
        patient_viewed_all_messages: false,
        pharmacy_viewed_all_messages: true,
        attachments: filesUploadResult.filesData.length
          ? filesUploadResult.filesData
          : [],
      },
    );

    const composedAttachments = await composeAttachments(
      messageFromServer.attachments,
      locationId,
      pharmacyId,
    );

    const newMessage: IMessageExtended = {
      _id: messageFromServer.id,
      createdAt: new Date(messageFromServer.created_at),
      user: typedMessage.user,
      text: typedMessage.text,
      attachments: composedAttachments,
      author_type: messageFromServer.author_type,
    };

    if (Object.keys(messageFromServer).length > 0) {
      const conversationMessages = [...selectedMessages, newMessage];
      setSelectedMessages(conversationMessages);
      setMessagesPagination({
        ...messagesPagination,
        count: messagesPagination.count + 1,
      });
    }
  };

  function addViewed(conversations: DirectMessagePatientDto[]) {
    const newViewed = [];
    const viewedMap = viewedConversations.reduce(
      (a, v) => ({ ...a, [v]: v }),
      {},
    );

    for (const conversation of conversations) {
      if (
        conversation.pharmacy_viewed_all_messages &&
        !(conversation.conversation_id in viewedMap)
      ) {
        newViewed.push(conversation.conversation_id);
      }
    }

    if (newViewed.length) {
      setViewedConversations([...viewedConversations, ...newViewed]);
    }
  }

  const onSelectConversation = async (
    conversation: DirectMessagePatientDto,
  ) => {
    const isSameConversation =
      conversation.conversation_id === selectedConversation?.conversation_id;
    setLoading(LOADING_MESSAGES, true);

    if (selectedMessages.length) {
      removeSelectedMessages();
    }

    if (isSameConversation) {
      removeSelectedConversation();
      return;
    }

    setSelectedConversation(conversation);
    const messagesResult = await getMessages(
      conversation.location_patient_id,
      conversation.conversation_id,
      0,
      TAKE,
    );
    setSelectedMessages(messagesResult.messages);
    setMessagesPagination({
      skip: 0,
      take: TAKE,
      count: messagesResult.count,
    });

    await updateConversationStatus(
      locationId,
      conversation.location_patient_id,
      conversation.conversation_id,
      conversation.patient_viewed_all_messages,
      true,
    );
    setLoading(LOADING_MESSAGES, false);
  };

  const onMarkUnread = async (
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
  ) => {
    await updateConversationStatus(
      locationId,
      locationPatientId,
      conversationId,
      patientViewedAllMessages,
      false,
    );
    removeSelectedConversation();
  };

  const onCancelModal = () => {
    closeNewChatModal();
    setInputFieldsData({});
  };

  const onConversationCreate = async (data: CreateConversationData) => {
    setLoading(LOADING_CONVERSATION_CREATING, true);
    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      data.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING_MODAL,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      setLoading(LOADING_CONVERSATION_CREATING, false);
      return;
    }

    const createConversationDto = {
      author_id: String(user._id),
      author_type: AuthorType.Pharmacy,
      subject: data.subject,
      patient_viewed_all_messages: false,
      pharmacy_viewed_all_messages: true,
      content: data.message,
      attachments: filesUploadResult.filesData.length
        ? filesUploadResult.filesData
        : [],
    };

    await unifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      data.patient,
      createConversationDto,
    );

    setLoading(LOADING_CONVERSATION_CREATING, false);
    onCancelModal();

    if (selectedPatient) {
      removeSelectedPatient();
      removeSelectedConversation();
    }

    const filterCriteria = getFilterCriteria();
    const rawConversationsData: DirectMessagePatientDto[] =
      await unifiedCommsService.getAllFilteredConversations(
        pharmacyId,
        locationId,
        filterCriteria,
      );
    const groupedConversations = groupConversationsByPatient(
      rawConversationsData,
    ).sort((currentConversation, nextConversation) =>
      compare(
        currentConversation,
        nextConversation,
        conversationsSorting.field,
        conversationsSorting.order,
        conversationsSorting.isDate,
      ),
    );

    setRawConversations(rawConversationsData);
    setPatients(groupedConversations);
  };

  const onPatientFilter = (text: string) => {
    if (isDateOfBirth(text)) {
      setTextFilters({
        ...textFilters,
        full_name: '',
        date_of_birth: text,
      });
    } else {
      setTextFilters({
        ...textFilters,
        full_name: text,
        date_of_birth: '',
      });
    }
  };

  const onEditSuccess = async (
    locationPatientRecord: LocationPatientRecordDto | null,
  ) => {
    if (!locationPatientRecord) {
      return;
    }
    const { first_name, last_name, date_of_birth } = locationPatientRecord;
    if (
      selectedPatient?.first_name !== first_name ||
      selectedPatient.last_name === last_name ||
      selectedPatient.date_of_birth === date_of_birth
    ) {
      const dataToUpdate: UpdatePatientWithConversationsDto = {
        first_name,
        last_name,
        date_of_birth,
      };

      await unifiedCommsService.updatePatientWithConversations(
        locationId,
        locationPatientRecord.id,
        dataToUpdate,
      );

      const rawConversationsData: DirectMessagePatientDto[] =
        await unifiedCommsService.getAllPatientsWithConversationsByLocation(
          pharmacyId,
          locationId,
        );
      const groupedConversations = groupConversationsByPatient(
        rawConversationsData,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          conversationsSorting.field,
          conversationsSorting.order,
          conversationsSorting.isDate,
        ),
      );
      setRawConversations(rawConversationsData);
      setPatients(groupedConversations);
    }

    const selectedPatientLocationRecord =
      await PatientService.getPatientLocationRecord(
        locationId,
        locationPatientRecord.id,
      );
    setSelectedPatient(selectedPatientLocationRecord);
  };

  function getContent() {
    if (rawConversationsCount) {
      return (
        <View style={styles.body}>
          <View
            style={[
              styles.conversationsContainer,
              Boolean(selectedPatient) && { width: CONVERSATIONS_TABS_WIDTH },
            ]}
          >
            <View style={{ flexDirection: 'column' }}>
              <View style={styles.actions}>
                <ToolbarSortingDropDown
                  value={conversationsSorting.value}
                  onChange={(option) =>
                    setConversationsSorting(option as IConversationsSorting)
                  }
                  options={sortingOptions}
                  size="min"
                  styles={{
                    control: {
                      fontSize: 13,
                    },
                    singleValue: {
                      color: theme.palette.gray[900],
                    },
                    dropdownIndicator: {
                      color: theme.palette.gray[900],
                    },
                  }}
                />
                <View style={styles.rightActions}>
                  <TouchableOpacity
                    onPress={notImplementedAlert}
                    disabled={false}
                  >
                    <ArrowUpFromLineIcon size={14} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={onAddPatientClick}
                    disabled={false}
                  >
                    <PlusInCircleIcon size={16} />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.searchSection}>
                <View style={styles.patientSearchIcon}>
                  <SearchIcon size={18} color={theme.palette.gray[400]} />
                </View>
                <TextInput
                  placeholder={getText('find-patient')}
                  autoComplete="off"
                  autoCapitalize="none"
                  style={[
                    styles.patientSearch,
                    Boolean(selectedPatient) && { width: '100%' },
                  ]}
                  mode="outlined"
                  outlineColor={theme.palette.white}
                  children={undefined}
                  onChangeText={onPatientFilter}
                  value={textFilters.full_name || textFilters.date_of_birth}
                />
              </View>
            </View>
            <View
              style={{
                marginRight: theme.getSpacing(8) + theme.getSpacing(4),
              }}
            >
              {Boolean(patients.length) &&
                patients.map((patient, index) => (
                  <Fragment key={patient.location_patient_id}>
                    <TouchableOpacity
                      onPress={() => void onSelectPatient(patient)}
                      disabled={false}
                    >
                      <View
                        style={[
                          styles.patient,
                          index + 1 === patients.length && styles.isLast,
                        ]}
                      >
                        <PatientConversation
                          patient={patient}
                          isViewed={patient.pharmacy_viewed_all_messages}
                          isSelected={
                            patient.location_patient_id === selectedPatient?.id
                          }
                        />
                      </View>
                    </TouchableOpacity>
                  </Fragment>
                ))}
            </View>
          </View>
          {selectedPatient && (
            <View style={{ flex: 1 }}>
              <View style={styles.messagesHeader}>
                <View style={{ flexDirection: 'row' }}>
                  <Avatar
                    size={40}
                    name={getFullName(selectedPatient)}
                    color={theme.palette.gray[100]}
                  />
                  <View style={styles.userInfo}>
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{ marginRight: theme.getSpacing(8) }}>
                        {getFullName(selectedPatient)}
                      </Text>
                      <TouchableOpacity onPress={() => setShowEditModal(true)}>
                        <PencilIcon size={16} />
                      </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      {selectedPatient.date_of_birth ? (
                        <Text
                          style={{
                            marginRight:
                              theme.getSpacing(8) + theme.getSpacing(4),
                          }}
                        >
                          {formatDate(selectedPatient.date_of_birth)}
                        </Text>
                      ) : null}
                      {selectedPatient.phone ? (
                        <Text>{prettyFormat(selectedPatient.phone)}</Text>
                      ) : null}
                    </View>
                  </View>
                </View>
                <Button
                  hierarchy="pharmacy-primary"
                  size="small"
                  logger={{ id: 'new-subject-button' }}
                  style={{
                    height: theme.getSpacing(32) + theme.getSpacing(4),
                  }}
                  onPress={() => openNewChatModal()}
                >
                  {getText('new-subject')}
                </Button>
              </View>
              <VerificationAlert locationId={locationId} />
              <View style={styles.messagesBlock}>
                {user &&
                  messagesGroups.map((conversation) => (
                    <Fragment key={conversation.conversation_id}>
                      <ConversationBox
                        user={user}
                        messages={selectedMessages}
                        typingMember={typingMember}
                        onTyping={() => onType(conversation.conversation_id)}
                        isOpen={
                          conversation.conversation_id ===
                          selectedConversation?.conversation_id
                        }
                        subject={conversation.subject}
                        onSendMessage={onSend}
                        isViewed={viewedConversations.includes(
                          conversation.conversation_id,
                        )}
                        lastMessageTime={
                          conversation.most_recent_qualifying_message_date
                        }
                        onHeaderClick={() =>
                          void onSelectConversation(conversation)
                        }
                        onMarkUnread={() =>
                          void onMarkUnread(
                            conversation.location_patient_id,
                            conversation.conversation_id,
                            conversation.patient_viewed_all_messages,
                          )
                        }
                        messagesCount={messagesPagination.count}
                        conversationId={conversation.conversation_id}
                        loadMore={loadMore}
                        renderLoadEarlier={renderLoadMore}
                      />
                    </Fragment>
                  ))}
              </View>
            </View>
          )}
        </View>
      );
    } else if (!pharmacyHasPatientRecords) {
      return (
        <View style={styles.noPatientsWrapper}>
          <MessageCircleIcon size={45} />
          <Text style={[styles.noPatientsSecondaryText, styles.bold]}>
            {getText('add-patients-start-messaging')}
          </Text>
          <Button
            hierarchy="pharmacy-secondary"
            size="small"
            logger={{ id: 'add-patient-button' }}
            style={{ marginTop: theme.getSpacing(8) + theme.getSpacing(4) }}
            onPress={notImplementedAlert}
          >
            {getText('add-patient')}
          </Button>
          <Button
            hierarchy="pharmacy-primary"
            size="small"
            logger={{ id: 'add-upload-patients-csv-button' }}
            style={{ marginTop: theme.getSpacing(8) + theme.getSpacing(4) }}
            onPress={notImplementedAlert}
          >
            {getText('upload-patients-csv')}
          </Button>
        </View>
      );
    }

    return (
      <View style={styles.noPatientsWrapper}>
        <MessageCircleIcon size={45} />
        <Text style={styles.noPatientsText}>{getText('no-messages')}</Text>
        <Text>{getText('add-mobile-number-send-message')}</Text>
        <Button
          hierarchy="pharmacy-secondary"
          size="small"
          logger={{ id: 'add-mobile-number-button' }}
          style={{ marginTop: theme.getSpacing(8) + theme.getSpacing(4) }}
          onPress={notImplementedAlert}
        >
          {getText('add-mobile-number')}
        </Button>
      </View>
    );
  }

  const loadMore = async (args: LoadMoreOptions) => {
    const conversation = messagesGroups.find(
      (group) =>
        group.conversation_id === selectedConversation?.conversation_id,
    );
    if (conversation && selectedConversation) {
      const messagesResult = await getMessages(
        conversation.location_patient_id,
        selectedConversation.conversation_id,
        args.skip,
        args.take,
      );

      setSelectedMessages(messagesResult.messages);
      setMessagesPagination({
        skip: args.skip,
        take: args.take,
        count: messagesResult.count,
      });
    }
  };

  const onAddPatientClick = useCallback(() => {
    setShowAddPatientModal(true);
  }, [setShowAddPatientModal]);

  const createPatientCB = useCallback(
    async (patientRecord: PatientRecordDto) => {
      if (patientRecord.location_patient_records.length) {
        const location_patient_id =
          patientRecord.location_patient_records[0].id;
        const patientLocationRecord =
          await PatientService.getPatientLocationRecord(
            locationId,
            location_patient_id,
          );

        setSelectedPatient(patientLocationRecord);
        openNewChatModal();
      } else {
        console.error('Location Patient Record was not created');
      }
    },
    [locationId],
  );

  const renderLoadMore = useCallback(() => {
    if (messagesPagination.skip + TAKE > messagesPagination.count) {
      return <></>;
    }
    return (
      <View style={{ height: 50, marginTop: 10 }}>
        <LoadingIndicator color={theme.palette.primary[700]} />
      </View>
    );
  }, [messagesPagination.skip, messagesPagination.count]);

  return (
    <View style={styles.container}>
      <ErrorsContainer
        errorObject={errorObject}
        excludedErrors={EXCLUDED_ERRORS_INBOX}
      />
      <NewChatModal
        show={isOpenNewChatModal}
        onConversationCreate={onConversationCreate}
        onCancel={onCancelModal}
        recipient={selectedPatient}
        templates={templates}
        isConversationCreating={
          LOADING_CONVERSATION_CREATING in loadingObject &&
          loadingObject[LOADING_CONVERSATION_CREATING].isLoading
        }
      />
      <AddPatientModal
        show={showAddPatientModal}
        setShowAddPatientModal={setShowAddPatientModal}
        locationId={locationId}
        pharmacyId={pharmacyId}
        successCB={createPatientCB}
      />
      {selectedPatient ? (
        <EditPatientModal
          show={showEditModal}
          locationPatientRecord={selectedPatient}
          setShowEditModal={setShowEditModal}
          patientDetails={selectedPatient}
          successCB={onEditSuccess}
        />
      ) : (
        <></>
      )}
      <View style={styles.tabHeader}>
        <Text style={styles.headerText}>{getText('chat-messages')}</Text>
      </View>
      {getContent()}
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    margin: theme.getSpacing(32),
  },
  tabHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(8) + theme.getSpacing(4),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
  },
  headerText: {
    ...theme.fonts.medium,
    fontSize: 26,
    marginRight: theme.getSpacing(32),
  },
  headerSearch: {
    backgroundColor: '#EAF1F4', // blue-gray
    height: 44,
    width: 320,
  },
  noPatientsWrapper: {
    alignItems: 'center',
    marginTop: '15%',
  },
  noPatientsText: {
    ...theme.fonts.medium,
    fontSize: 20,
    marginTop: theme.getSpacing(8) + theme.getSpacing(4),
  },
  noPatientsSecondaryText: {
    marginTop: theme.getSpacing(8) + theme.getSpacing(4),
  },
  bold: {
    fontWeight: '600',
  },
  body: {
    flexDirection: 'row',
    marginTop: 12,
    width: '100%',
  },
  conversationsContainer: {
    width: '100%',
    flexDirection: 'column',
    marginRight: theme.getSpacing(8) + theme.getSpacing(4),
  },
  patient: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray[100],
    cursor: 'pointer',
  },
  isLast: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray[100],
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[100],
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 46,
  },
  searchSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
  },
  patientSearch: {
    height: 36,
    width: CONVERSATIONS_TABS_WIDTH,
    marginLeft: -(theme.getSpacing(32) + theme.getSpacing(8)),
    paddingLeft: theme.getSpacing(32) + theme.getSpacing(8),
  },
  patientSearchIcon: {
    zIndex: 1,
    marginTop: theme.getSpacing(8),
    marginLeft: theme.getSpacing(24),
  },
  messagesHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
  },
  messagesBlock: {
    width: '100%',
    height: '100%',
  },
  userInfo: {
    justifyContent: 'space-between',
    marginLeft: theme.getSpacing(8) + theme.getSpacing(4),
  },
}));
