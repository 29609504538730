import { FunctionComponent, useEffect, useMemo } from 'react';
import { View } from 'react-native';

import { AppointmentTypeScheduleDto } from '@digitalpharmacist/appointment-service-client-axios';

import { useForm } from 'assets/form';
import { makeStyles, useTheme } from 'assets/theme';
import { Form } from 'assets/layout';
import { Text } from 'assets/components/text';

import {
  AvailabilitiesData,
  availabilitiesToFormData,
  availabilityFields,
} from '../availability-form/availability-form-utils';
import { AvailabilityFormData } from '../availability-form/AvailabilityForm';
import { TimeRangeInput } from '../time-range-input/TimeRangeInput';

const isAvailabilityDefined = (
  availabilities: AvailabilitiesData,
  key: keyof AvailabilitiesData,
): boolean => {
  const dailyAvailability = availabilities[key] as {
    start: string;
    end: string;
  };

  return !!(dailyAvailability?.start && dailyAvailability?.end);
};

type WeeklyScheduleForm = Omit<
  AvailabilityFormData,
  'title' | 'isDefault' | 'availabilityOverrides'
>;

export const PreviewAvailability: FunctionComponent<
  PreviewAvailabilityProps
> = ({ schedule }) => {
  const styles = useStyles();

  const availabilities = useMemo(
    () =>
      availabilitiesToFormData(
        schedule.availability.filter((availability) => !availability.date),
      ),
    [schedule],
  );

  const methods = useForm<WeeklyScheduleForm>({
    defaultValues: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
  });

  useEffect(() => {
    const formData: WeeklyScheduleForm = {
      monday: availabilities.monday || null,
      tuesday: availabilities.tuesday || null,
      wednesday: availabilities.wednesday || null,
      thursday: availabilities.thursday || null,
      friday: availabilities.friday || null,
      saturday: availabilities.saturday || null,
      sunday: availabilities.sunday || null,
    };

    methods.reset(formData);
  }, [availabilities]);

  return (
    <View style={styles.container}>
      <Form methods={methods}>
        {availabilityFields.map((field) => (
          <View style={styles.containerHorizontal}>
            <Text
              style={[
                styles.fieldLabel,
                !isAvailabilityDefined(
                  availabilities,
                  field.name as keyof AvailabilitiesData,
                ) && styles.disabledFieldLabel,
              ]}
            >
              {field.label}
            </Text>
            <TimeRangeInput disabled={true} name={field.name} />
          </View>
        ))}
      </Form>
    </View>
  );
};

interface PreviewAvailabilityProps {
  schedule: AppointmentTypeScheduleDto;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.getSpacing(8),
  },
  containerHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(8),
  },
  fieldLabel: {
    fontSize: 16,
    width: 100,
  },
  disabledFieldLabel: {
    color: theme.colors.disabled,
  },
}));
