import React, { FunctionComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { ColoredBadge } from '../../../../components/ColoredBadge';

export const ButtonFilter: FunctionComponent<ButtonFilterProps> = ({
  active,
  name,
  icon,
  count,
  value,
  disabled,
  onPress,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <TouchableOpacity onPress={() => onPress(value)} disabled={disabled}>
      <View
        style={[
          styles.filter,
          active && styles.filterActive,
          disabled && styles.filterDisabled,
        ]}
      >
        <Icon
          icon={icon}
          color={active ? theme.palette.primary[600] : theme.palette.gray[500]}
        />
        <Text
          style={[styles.filterText, active && styles.filterTextActive]}
          selectable
        >
          {name}
        </Text>
        {count !== undefined && (
          <ColoredBadge
            backgroundColor={
              active ? theme.palette.primary[600] : theme.palette.gray[200]
            }
            color={active ? theme.palette.white : theme.palette.gray[900]}
            label={String(count)}
            textStyle={styles.filterBadgeText}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export interface ButtonFilterProps {
  active: boolean;
  name: string;
  icon: React.FunctionComponent;
  value: string | number;
  count?: number;
  disabled?: boolean;
  onPress: (value: string | number) => void;
}

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: theme.getSpacing(8),
    paddingHorizontal: theme.getSpacing(24),
    alignItems: 'center',
    cursor: 'pointer',
  },
  filterActive: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[600],
  },
  filterDisabled: {
    opacity: 0.5,
  },
  filterText: {
    marginLeft: theme.getSpacing(8),
    marginRight: 'auto',
    marginVertical: 5,
    color: theme.palette.gray[900],
    fontWeight: '500',
  },
  filterTextActive: {
    color: theme.palette.primary[600],
    fontWeight: '700',
  },
  filterBadgeText: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 50,
    fontSize: 12,
  },
}));
