import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'assets/components/button';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import { useTheme } from 'assets/theme';

export const NewAppointmentModal: FunctionComponent<
  PropsWithChildren<NewAppointmentModalProps>
> = () => {
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const theme = useTheme();

  return (
    <>
      <Button
        hierarchy="pharmacy-primary"
        size="small"
        logger={{ id: 'new-appointment-button' }}
        style={{
          margin: theme.getSpacing(16),
        }}
        onPress={() => navigation.navigate('new-appointment', {})}
      >
        New Appointment
      </Button>
    </>
  );
};

interface NewAppointmentModalProps {}

export default NewAppointmentModal;
