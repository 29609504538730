import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';

import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';

const SettingsPageLayout: FunctionComponent<
  PropsWithChildren<SettingsPageLayoutProps>
> = ({ title, children }) => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title} selectable>
          {title}
        </Text>
      </View>
      {children}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    margin: theme.getSpacing(32),
    height: '100%',
  },
  title: {
    fontSize: 25,
    paddingBottom: theme.getSpacing(32),
    marginBottom: theme.getSpacing(24),
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
  },
}));

interface SettingsPageLayoutProps {
  title: string;
}

export default SettingsPageLayout;
