import React, {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';
import { View } from 'react-native';
import shallow from 'zustand/shallow';

import { Modal } from 'assets/components/modal';
import { makeStyles } from 'assets/theme';

import { useFormsImportStepperState } from './forms-import-stepper-store';
import { IMPORT_STEPPER_CONTENT } from './forms-import-stepper.content';
import { navigateToStep } from './forms-import-stepper-actions';

import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { StepperProgress } from './stepper-progress/StepperProgress';
import { Steps } from './forms-import-stepper.types';
import { getForms } from '../forms-data-table/forms-data-table-actions';
import { FormStatus } from '@digitalpharmacist/forms-service-client-axios';

export const FormsImportStepper: FunctionComponent<
  PropsWithChildren<FormsImportStepperProps>
> = () => {
  const styles = useStyles();
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const { currentStep, steps, reset, isOpen } = useFormsImportStepperState(
    (state) => ({
      currentStep: state.currentStep,
      steps: state.steps,
      reset: state.reset,
      isOpen: state.isOpen,
    }),
    shallow,
  );

  const {
    component: CurrentComponent,
    buttonText,
    hasNavigationButtons,
  } = IMPORT_STEPPER_CONTENT[currentStep.index];

  const closeModal = () => {
    setShowCloseConfirmation(false);
    reset();
  };

  const handleCloseLastStep = () => {
    getForms(FormStatus.Enabled);
    getForms(FormStatus.Disabled);
    closeModal();
  };

  const cancelButtonProps = useMemo(() => {
    if (hasNavigationButtons) {
      return {
        onPress: currentStep.index
          ? () => setShowCloseConfirmation(true)
          : closeModal,
        logger: { id: 'new-form-cancel-button-modal' },
      };
    }
  }, [currentStep.index]);

  return (
    <>
      <Modal
        title="Form Import"
        size="lg"
        titleSize="sm"
        dismissButtonProps={cancelButtonProps}
        cancelButtonProps={cancelButtonProps}
        deleteButtonProps={
          currentStep.index && hasNavigationButtons
            ? {
                hierarchy: 'secondary-gray',
                onPress: navigateToStep,
                logger: { id: 'new-form-cancel-button-modal' },
                pinLeft: true,
                text: 'Back',
                icon: undefined,
                style: { width: 120, justifyContent: 'center' },
              }
            : undefined
        }
        okButtonProps={{
          onPress:
            currentStep.index === 4
              ? handleCloseLastStep
              : () => navigateToStep('Next'),
          logger: { id: 'new-form-ok-button-modal' },
          hierarchy: 'pharmacy-primary',
          text: buttonText,
          disabled: steps[currentStep.id as keyof Steps]?.invalid,
        }}
        show={isOpen}
        headerComponent={
          <View style={styles.stepperWrapper}>
            <StepperProgress
              currentStep={currentStep?.index + 1}
              numberOfSteps={5}
            />
          </View>
        }
      >
        <CurrentComponent />
      </Modal>
      <PharmacyConfirmationModal
        show={showCloseConfirmation}
        onAccepted={closeModal}
        onDismiss={() => setShowCloseConfirmation(false)}
        message="By confirming you will lose the current progress on importing a form."
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  stepperWrapper: {
    paddingHorizontal: 0,
    paddingBottom: theme.getSpacing(16),
  },
  buttonSpacing: {
    margin: theme.getSpacing(16),
  },
}));

interface FormsImportStepperProps {}

export default FormsImportStepper;
