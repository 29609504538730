import theme, { makeStyles } from 'assets/theme';
import { UserTyping } from '../hooks/useSockets';
import { View, Text } from 'react-native';
import { DotTypingAnimation } from 'react-native-dot-typing';

interface WritingBarProps {
  typingMember?: UserTyping | null;
  conversationId: string;
}

const WritingBar = ({ typingMember, conversationId }: WritingBarProps) => {
  const styles = useStyles();

  if (!typingMember) {
    return null;
  }

  const arr = typingMember.name.split(' ');
  const abbreviation =
    arr[0].charAt(0).toLocaleUpperCase() + arr[1].charAt(0).toLocaleUpperCase();
  return conversationId === typingMember.conversation_id ? (
    <View style={styles.writerBarContainer}>
      <View
        style={[
          styles.innerContainer,
          typingMember.author_type === 'pharmacy'
            ? styles.pharmacyWriting
            : styles.patientWriting,
        ]}
      >
        <View style={styles.avatar}>
          <Text>{abbreviation}</Text>
        </View>
        <View style={styles.box}>
          <View style={styles.bubbleContainer}>
            <DotTypingAnimation
              dotColor={theme.colors.disabled}
              dotRadius={5}
            />
          </View>
        </View>
      </View>
    </View>
  ) : null;
};

export default WritingBar;

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 0,
    backgroundColor: theme.palette.gray[100],
    padding: theme.getSpacing(16),
    minHeight: 40,
    minWidth: 55,
    borderRadius: 8,
  },
  bubbleText: {
    ...theme.fonts.regular,
    color: theme.palette.black,
  },
  writerBarContainer: {
    padding: 7,
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  innerContainer: {
    alignItems: 'center',
  },
  pharmacyWriting: {
    flexDirection: 'row-reverse',
  },
  patientWriting: {
    flexDirection: 'row',
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.gray[100],
  },
  box: {
    padding: 7,
    flexDirection: 'column',
  },
}));
