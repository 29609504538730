import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import { GridReadyEvent, GridApi } from '@ag-grid-community/core';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
  AppointmentTypeDto,
  BookingDto,
  BookingPageDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { makeStyles, useTheme } from 'assets/theme';
import { DataGrid } from 'assets/components/data-grid';
import { IconButton } from 'assets/components/icon-button';
import {
  ClockIcon,
  PencilIcon,
  UserXIcon,
  MessageSquareIcon,
} from 'assets/icons';

import NoResultsOverlay from '../../components/NoResultsOverlay';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import AppointmentService from '../../api/AppointmentService';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  calculatePatientAge,
  convertDateTimeFromUtcToPharmacyTimezone,
  DEFAULT_DATE_TIME_API_FORMAT,
  DEFAULT_PHARMACY_TIMEZONE,
  formatDate,
  formatDateTimeApi,
  formatUTCToRelative,
} from '../../common/datetime-utils';
import { ColoredBadge } from '../../components/ColoredBadge';
import {
  AppointmentsFindParams,
  minutesDifference,
  STATUS_STYLE_MAPPING,
} from './appointments-list.utils';
import {
  cancelBooking,
  showAppointmentDetails,
} from './appointments-list-actions';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { getText } from '../../../../../packages/assets/localization/localization';
import { useProSidebar } from 'react-pro-sidebar';
import { AppointmentDetailsSidebar } from './AppointmentDetailsSidebar';
import { Tooltip } from '../../components/Tooltip';
import { TooltipWrapper } from 'react-tooltip';
import moment from 'moment';
import { useAppointmentFiltersState } from '../appointment-filters/appointment-filters-store';
import { composeAppointmentFiltersMessage } from '../appointment-filters/appointment-filters.utils';
import { EllipsisTextRendererTooltip } from '../../common/EllipsisTextRendererTooltip';

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: '100%' }}></View>;
};

const StatusRenderer = (props: { data: BookingItem }) => {
  const styles = useStyles();
  const rowData = props.data;

  return (
    <View style={styles.cellContainer}>
      <ColoredBadge
        {...STATUS_STYLE_MAPPING[rowData.status]}
        textStyle={styles.badgeText}
      />
    </View>
  );
};

const ActionButtonsRenderer = (props: {
  data: AppointmentTypeDto;
  api: GridApi;
}) => {
  const styles = useStyles();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const rowData = props.data;

  const handleCancelBooking = async () => {
    setShowModal(false);
    setLoading(true);
    await cancelBooking(rowData.id);
    props.api.refreshServerSideStore();
    setLoading(false);
  };

  return (
    <View style={styles.cellContainer}>
      <TooltipWrapper tooltipId="appointments-list-edit-tooltip">
        <IconButton
          icon={PencilIcon}
          logger={{ id: `edit-service--${rowData.id}` }}
          onPress={() =>
            navigation.navigate('edit-appointment', {
              appointmentId: rowData.id,
            })
          }
        />
      </TooltipWrapper>
      <TooltipWrapper tooltipId="appointments-list-cancel-tooltip">
        <IconButton
          icon={UserXIcon}
          logger={{ id: `cancel-service--${rowData.id}` }}
          onPress={() => setShowModal(true)}
          disabled={loading}
        />
      </TooltipWrapper>
      <TooltipWrapper tooltipId="appointments-list-message-tooltip">
        <IconButton
          icon={MessageSquareIcon}
          logger={{ id: `message-service--${rowData.id}` }}
          onPress={() =>
            alert('Under construction. Exciting things coming soon!')
          }
        />
      </TooltipWrapper>
      <PharmacyConfirmationModal
        show={showModal}
        message={getText('cancel-appointment-confirmation')}
        onAccepted={() => void handleCancelBooking()}
        onDismiss={() => setShowModal(false)}
      />
    </View>
  );
};

export const AppointmentsList: FunctionComponent = () => {
  const theme = useTheme();
  const [paginatedDatasource] = useState(
    AppointmentService.getAppointmentsPaginatedDatasource(
      `&min_end_date=${formatDateTimeApi(moment())}`,
    ),
  );
  const gridApiRef = useRef<GridApi>();

  const filters = useAppointmentFiltersState((state) => state.filters);
  const isCustomFilter = useAppointmentFiltersState(
    (state) => state.isCustomFilter,
  );
  const [tooltipData, setTooltipData] = useState<TooltipProps>();
  const { collapseSidebar } = useProSidebar();

  const [columnDefs] = useState([
    {
      field: 'full_name',
      colId: 'patient_record_first_name',
      sortable: true,
      headerName: getText('name'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'patient_record_date_of_birth',
      headerName: getText('birthdate'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={
            value && `${formatDate(value)} (${calculatePatientAge(value)})`
          }
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'title',
      headerName: getText('service'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'appointment_group_type',
      headerName: getText('service-category'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 140,
      field: 'duration',
      headerName: getText('duration'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={`${value} min`}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    // TODO: This is not yet implemented on the back-end, the hard-coded value needs to be replaced with the API data.
    {
      maxWidth: 150,
      field: 'location',
      headerName: getText('venue'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={getText('venue-in-person')}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 150,
      field: 'status',
      headerName: getText('status'),
      cellRenderer: StatusRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    },
    {
      maxWidth: 160,
      field: 'startTime',
      colId: 'start_time',
      sortable: true,
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={formatUTCToRelative(
            convertDateTimeFromUtcToPharmacyTimezone(
              value,
              DEFAULT_PHARMACY_TIMEZONE,
              DEFAULT_DATE_TIME_API_FORMAT,
            ),
            undefined,
            true,
            'US',
            undefined,
            undefined,
            true,
          )}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),

      headerName: getText('time'),
      headerClass: 'data-grid-header-right-aligned',
      cellClass: 'data-grid-cell-right-aligned',
    },

    {
      maxWidth: 200,
      field: 'actions',
      headerName: getText('actions'),
      headerClass: 'data-grid-header-right-aligned',
      cellRenderer: ActionButtonsRenderer,
    },
  ]);

  useFocusEffect(
    useCallback(() => {
      gridApiRef.current?.dispatchEvent({ type: 'filterChanged' });
    }, [filters]),
  );

  const handleCancel = () => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshServerSideStore();
    }
  };

  const handleCollapse = () => {
    if (gridApiRef.current) {
      gridApiRef.current.deselectAll();
    }
  };

  return (
    <>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <DataGrid
            gridOptions={{
              onGridReady(event: GridReadyEvent) {
                gridApiRef.current = event.api;
              },
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressRowClickSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: true,
              paginationPageSize: 10,
              cacheBlockSize: 10,
              rowModelType: 'serverSide',
              serverSideStoreType: 'partial',
              serverSideDatasource: paginatedDatasource,
              loadingCellRendererSelector: (params) => {
                // This is a temporary workaround to only show one loading indicator per table row
                // What we want to do in the future is create a RowLoadingPlaceholder which would indicate the loading state for the row
                if (
                  params.rowIndex == 0 ||
                  (params.rowIndex % 5 == 0 && params.rowIndex % 10 !== 0)
                ) {
                  return {
                    component: LoadingOverlay,
                  };
                }

                return {
                  component: NoLoading,
                };
              },
              context: {
                transformResponse(params: BookingPageDto) {
                  const { results, total } = params;

                  const resultsWithUserData = results.map((x) => {
                    return {
                      ...x,
                      full_name: `${x.patient_record_first_name} ${x.patient_record_last_name}`,
                      duration: minutesDifference(x.endTime, x.startTime),
                    };
                  });

                  return {
                    total,
                    results: resultsWithUserData,
                  };
                },
                transformRequest(params: AppointmentsFindParams) {
                  return {
                    ...params,
                    ...filters,
                  };
                },
              },
              noRowsOverlayComponent: () => (
                <NoResultsOverlay
                  title={getText('no-appointments')}
                  subtitle={getText('check-later')}
                  icon={
                    <ClockIcon size={100} color={theme.palette.gray[300]} />
                  }
                  layout="vertical"
                />
              ),
              onCellClicked(event) {
                // We don't want to open the sidepanel if the user clicked on one of the actions buttons
                // unfortunately ag-grid propagates this event no matter what (eg. suppressRowClickSelection)
                // sidepanel won't trigger if the user clicks on the padding surrounding the action buttons @rtud
                if (event.column && event.column.getColId() !== 'actions') {
                  showAppointmentDetails(event.data.id);
                  event.api.selectNode(event.node);
                  collapseSidebar(false);
                }
              },
            }}
            gridToolbarProps={{
              titleProps: {
                title: `${getText('upcoming')}  ${getText('appointments')}`,
              },
              toolbarContentBoxProps: {
                isVisible: isCustomFilter,
                title: getText('appointments-filters-info-title'),
                description: composeAppointmentFiltersMessage(),
              },
            }}
          />
        </View>
        <AppointmentDetailsSidebar
          onCancel={handleCancel}
          onCollapse={handleCollapse}
        />

        <Tooltip
          id="appointments-list-edit-tooltip"
          text={getText('edit-reschedule-this-appointment')}
        />
        <Tooltip
          id="appointments-list-cancel-tooltip"
          text={getText('cancel-this-appointment')}
        />
        <Tooltip
          id="appointments-list-message-tooltip"
          text={getText('message-this-patient')}
        />
      </View>
      {tooltipData && <Tooltip text={tooltipData.value} id={tooltipData.id} />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.getSpacing(32),
    fontSize: 20,
    marginBottom: theme.getSpacing(16),
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  badgeText: {
    fontSize: 14,
    lineHeight: 20,
    paddingLeft: theme.getSpacing(8),
    paddingRight: theme.getSpacing(8),
    paddingVertical: theme.getSpacing(4),
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 26,
    marginRight: theme.getSpacing(24),
    alignItems: 'center',
    display: 'flex',
    fontWeight: '600',
    zIndex: 10,
    marginBottom: -36,
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(8),
  },
  options: {
    flexDirection: 'row',
  },
  optionsLabel: {
    flex: 9,
  },
  checkIcon: {
    flex: 1,
  },
}));

interface BookingItem extends BookingDto {
  full_name?: string;
  date_of_birth?: string;
  duration?: number;
  service?: string;
}

interface CellRendererProps {
  value: string;
  rowIndex: number;
}

interface TooltipProps {
  value: string;
  id: string;
}
