import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Icon } from 'assets/components/icon';
import { ShareIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { Text, TouchableOpacity, View } from 'react-native';
import { VideoCategoryModel } from '../../api/VideoService';

const VideoBrowseTemplate: React.FC<VideoBrowseTemplateProps> = ({
  accountId,
  videoId,
  playerId,
  title,
  category,
}) => {
  const styles = useStyles();

  return (
    <View style={styles.card}>
      <ReactPlayerLoader
        accountId={accountId}
        videoId={videoId}
        playerId={playerId}
        embedOptions={{ responsive: true }}
        options={{
          autoplay: false,
          controls: false,
          preload: 'metadata',
          muted: true,
          click: false,
          fluid: true,
          playsinline: true,
        }}
        userActions={{ click: false }}
        disablePlayback={true}
      />
      <Text style={styles.title}>{title}</Text>
      <View style={styles.info}>
        <Text style={styles.category}>{category.label}</Text>
        <TouchableOpacity style={styles.share} onPress={() => {}}>
          <Icon icon={ShareIcon} color="#0093E8" size={14} />
          <Text style={styles.shareText}>{getText('videos-share')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default VideoBrowseTemplate;

export interface VideoBrowseTemplateProps {
  id: string;
  accountId: string;
  videoId: string;
  playerId: string;
  title: string;
  category: VideoCategoryModel;
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.getSpacing(16),
    marginLeft: theme.getSpacing(24),
    width: 244,
  },
  title: {
    marginTop: theme.getSpacing(8),
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 24,
  },
  info: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  category: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '400',
    color: '#374D58',
  },
  share: {
    marginLeft: theme.getSpacing(16),
    color: '#0093E8',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    display: 'flex',
  },
  shareText: {
    color: '#0093E8',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    marginLeft: theme.getSpacing(4),
  },
}));
