import { FunctionComponent, PropsWithChildren } from 'react';
import { View, TouchableOpacity } from 'react-native';
import shallow from 'zustand/shallow';

import { CheckboxBase } from '../../../../../packages/assets/components/checkbox/CheckboxBase';
import { Text } from '../../../../../packages/assets/components/text';
import { Button } from 'assets/components/button';
import { makeStyles } from '../../../../../packages/assets/theme';

import { useUserState } from '../../store/user-store';
import { TaskFilters, useTasksFiltersState } from './tasks-filters-store';
import {
  setBaseFilter,
  updateFiltersShowModalState,
  setCheckboxFilter,
  resetFilters,
} from './tasks-filters-actions';

import { TasksFilterButton, TasksFilterButtonProps } from './TasksFilterButton';
import {
  TasksFilterCheckbox,
  TasksFilterCheckboxProps,
} from './TasksFilterCheckbox';
import { TaskAdvancedFiltersModal } from './TasksAdvancedFiltersModal';
import { getText } from 'assets/localization/localization';

export const TasksFilters: FunctionComponent<TaskFiltersProps> = ({
  baseFilters,
  multiFilters,
}) => {
  const styles = useStyles();
  const userId = useUserState((state) => state.data?.id);

  const { filters, disabled, count, activeTab, isGrid, isCustomFilter } =
    useTasksFiltersState(
      (state) => ({
        filters: state.filters,
        disabled: state.disabled,
        count: state.count,
        activeTab: state.activeTab,
        isGrid: state.isGrid,
        isCustomFilter: state.isCustomFilter,
      }),
      shallow,
    );

  const setMyTasksCheckboxFilter = () => {
    setCheckboxFilter({ assigned_user_id: userId });
  };

  const isMyTasksChecked = useTasksFiltersState(
    (state) => state.filters?.assigned_user_id === userId,
  );

  const isCreatedByMeActive = useTasksFiltersState(
    (state) => state.filters?.created_by_user_id === userId,
  );

  const handleCustomCheckboxClick = () => resetFilters();

  return (
    <>
      <TouchableOpacity onPress={setMyTasksCheckboxFilter} disabled={disabled}>
        <View style={styles.checkboxContainer}>
          <CheckboxBase
            onPress={setMyTasksCheckboxFilter}
            isChecked={isMyTasksChecked}
            disabled={disabled}
          />
          <Text style={styles.checkboxLabel}>{getText('my-tasks')}</Text>
        </View>
      </TouchableOpacity>
      {baseFilters.map((filterProps) => (
        <TasksFilterButton
          key={filterProps.id}
          active={filterProps.id === activeTab}
          onPress={setBaseFilter}
          disabled={disabled}
          count={
            filterProps.id === 'flagged' ||
            filterProps.id === 'unresolved' ||
            filterProps.id === 'recurring' ||
            filterProps.id === 'personal'
              ? count[filterProps.id]
              : undefined
          }
          {...filterProps}
        />
      ))}
      <View style={styles.container}>
        <Text style={styles.filtersTitle}>Filters</Text>
        <Button
          hierarchy={'tertiary-gray'}
          onPress={() => updateFiltersShowModalState(true)}
          disabled={disabled}
          labelStyle={styles.buttonLabel}
          style={styles.buttonBorderColor}
          logger={{ id: 'advanced-filters-action-button-more' }}
        >
          More
        </Button>
      </View>
      {multiFilters.map((filterProps) => (
        <TasksFilterCheckbox
          key={filterProps.field}
          disabled={disabled}
          active={
            filterProps.field !== 'created_by_user_id'
              ? filters[filterProps.field] ===
                filterProps.value[filterProps.field]
              : isCreatedByMeActive
          }
          {...filterProps}
          onPress={filterProps.onPress}
        />
      ))}
      {isCustomFilter && (
        <View style={styles.container}>
          <TasksFilterCheckbox
            key={'isCustomFilter-checkbox'}
            name="Custom Filters"
            active={isCustomFilter}
            disabled={disabled}
            onPress={handleCustomCheckboxClick}
            value={{ isCustomFilter: false } as TaskFilters}
            field="priority"
          />
          <Button
            hierarchy={'tertiary-gray'}
            onPress={() => updateFiltersShowModalState(true)}
            disabled={disabled}
            labelStyle={styles.buttonLabel}
            style={[styles.buttonBorderColor, { marginTop: 0 }]}
            logger={{ id: 'advanced-filters-action-button-more' }}
          >
            Edit
          </Button>
        </View>
      )}
      <TaskAdvancedFiltersModal />
    </>
  );
};

interface TaskFiltersProps {
  baseFilters: Omit<TasksFilterButtonProps, 'onPress' | 'active'>[];
  multiFilters: Omit<TasksFilterCheckboxProps, 'active'>[];
}

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(8),
    paddingHorizontal: theme.getSpacing(24),
    cursor: 'pointer',
    marginVertical: theme.getSpacing(8),
  },
  checkboxLabel: {
    marginLeft: theme.getSpacing(8),
    color: theme.palette.gray[700],
    fontWeight: '500',
  },
  filtersTitle: {
    color: theme.palette.gray[500],
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: '600',
    paddingHorizontal: theme.getSpacing(24),
    marginBottom: theme.getSpacing(4),
    marginTop: theme.getSpacing(24),
  },
  buttonLabel: {
    color: theme.palette.primary[500],
    marginTop: theme.getSpacing(4),
    marginBottom: theme.getSpacing(4),
  },
  buttonBorderColor: {
    borderColor: 'transparent',
    marginHorizontal: 0,
    marginTop: theme.getSpacing(24),
    marginRight: 0,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
