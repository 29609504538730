import React from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import {
  TaskPriority,
  TaskStatus,
  TaskTypeDto,
  TaskVisibility,
} from '@digitalpharmacist/tasks-service-client-axios';
import { makeStyles, useTheme } from 'assets/theme';
import { OptionProps, SingleValueProps, PlaceholderProps } from 'react-select';
import { Avatar } from 'assets/components/avatar';
import { CheckIcon, UserIcon } from 'assets/icons';
import { Icon } from 'assets/components/icon';
import { PharmacyUser } from '../../../api';

export const TypeOptionTemplate = (props: OptionProps<TaskTypeDto>) => {
  const theme = useTheme();
  const styles = useStyles();

  const isSelected =
    (props.selectProps.value as TaskTypeDto)?.id === props.data.id;

  return (
    <View style={styles.dropdownOptionContainer}>
      {props.data.color && (
        <View style={styles.colorContainer}>
          <View
            style={[
              styles.colorCircle,
              {
                backgroundColor: props.data.color,
              },
            ]}
          />
        </View>
      )}
      <Text
        style={[
          styles.title,
          isSelected && { color: theme.palette.primary[600] },
        ]}
      >
        {props.data.title}
      </Text>

      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

export const TypeSingleValueTemplate = (
  props: SingleValueProps<TaskTypeDto>,
) => {
  const styles = useStyles();

  return (
    <View style={styles.dropdownOptionContainer}>
      {props.data.color && (
        <View style={styles.colorContainer}>
          <View
            style={[
              styles.colorCircle,
              {
                backgroundColor: props.data.color,
              },
            ]}
          />
        </View>
      )}
      <Text style={styles.title}>{props.data.title}</Text>
    </View>
  );
};

export const TypePlaceholderTemplate = (
  props: PlaceholderProps<TaskTypeDto>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text
        style={[
          styles.title,
          {
            color: theme.palette.gray[500],
          },
        ]}
      >
        Task Type
      </Text>
    </View>
  );
};

export const UserOptionTemplate = (props: OptionProps<PharmacyUser>) => {
  const theme = useTheme();
  const styles = useStyles();

  const isSelected =
    (props.selectProps.value as PharmacyUser)?.id === props.data.id;

  const isDefault = props.data.id === 'default';

  return (
    <View style={styles.dropdownOptionContainer}>
      {!isDefault && (
        <Avatar
          name={`${props.data.firstName} ${props.data.lastName}`}
          size={24}
        />
      )}
      <Text
        style={[
          styles.assigneeName,
          {
            marginRight: 'auto',
            marginLeft: isDefault ? 0 : theme.getSpacing(8),
          },
          isSelected && { color: theme.palette.primary[600] },
        ]}
      >{`${props.data.firstName} ${props.data.lastName}`}</Text>
      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

export const UserSingleValueTemplate = (
  props: SingleValueProps<PharmacyUser>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  const isDefault = props.data.id === 'default';

  return (
    <View style={styles.dropdownOptionContainer}>
      {!isDefault && (
        <Avatar
          name={`${props.data.firstName} ${props.data.lastName}`}
          size={24}
        />
      )}
      <Text
        style={[
          styles.assigneeName,
          { marginLeft: isDefault ? 0 : theme.getSpacing(8) },
        ]}
      >{`${props.data.firstName} ${props.data.lastName}`}</Text>
    </View>
  );
};

export const AssigneePlaceholderTemplate = (
  props: PlaceholderProps<PharmacyUser>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.dropdownOptionContainer}>
      <Icon icon={UserIcon} size={24} color={theme.palette.gray[500]} />
      <Text
        style={[
          styles.assigneeName,
          {
            color: theme.palette.gray[500],
          },
        ]}
      >
        Assignee
      </Text>
    </View>
  );
};

export const UserPlaceholderTemplate = (
  props: PlaceholderProps<PharmacyUser>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text
        style={{
          color: theme.palette.gray[500],
          fontSize: 16,
        }}
      >
        All users
      </Text>
    </View>
  );
};

export const GenericOptionTemplate = (
  props: OptionProps<GenericTypeOptionProps>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  const isSelected =
    (props.selectProps.value as GenericTypeOptionProps)?.id === props.data?.id;

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text
        style={[
          styles.title,
          isSelected && { color: theme.palette.primary[600] },
        ]}
      >
        {props.data.title}
      </Text>

      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

export const GenericSingleValueTemplate = (
  props: SingleValueProps<GenericTypeOptionProps>,
) => {
  const styles = useStyles();

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text style={styles.title}>{props.data.title}</Text>
    </View>
  );
};

export const GenericPlaceholderTemplate = (
  props: PlaceholderProps<GenericTypeOptionProps>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text
        style={[
          styles.title,
          {
            color: theme.palette.gray[500],
          },
        ]}
      >
        All
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  colorContainer: {
    backgroundColor: theme.palette.white,
    width: theme.getSpacing(16),
    height: theme.getSpacing(16),
    padding: 2,
    marginRight: 6,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: theme.palette.gray[400],
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 18,
    marginRight: 'auto',
  },
  dropdownOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(4),
    margin: 0,
  },
  assigneeName: {
    marginLeft: theme.getSpacing(8),
    fontSize: 16,
  },
  colorCircle: {
    borderRadius: 100,
    height: '100%',
  },
}));

interface GenericTypeOptionProps {
  title: string;
  id: TaskPriority | TaskStatus | TaskVisibility | string;
}
