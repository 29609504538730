import { Dimensions, View, Text } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import React, { FC, useEffect, useState } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { IconButton } from 'assets/components/icon-button';
import { XIcon, DownloadIcon } from 'assets/icons';
import FileStorageService from '../../../api/FileStorageService';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';

interface ChatDocumentViewerProps {
  chatDocumentViewerState: IChatDocumentViewerState;
  setChatDocumentViewerState: (args: IChatDocumentViewerState) => void;
  downloadAttachment: (storedFilename: string) => Promise<void>;
}

interface IChatDocumentViewerState {
  show: boolean;
  stored_filename: string;
  name: string;
}

const WIDTH_PERCENT = 0.9;
const HEIGHT_PERCENT = 0.9;

const ChatDocumentViewer: FC<ChatDocumentViewerProps> = ({
  chatDocumentViewerState,
  setChatDocumentViewerState,
  downloadAttachment,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const { locationId, pharmacyId } = useAppStateStore();

  const [url, setUrl] = useState('');

  useEffect(() => {
    const getAndSetUrl = async () => {
      try {
        const urlResponse = await FileStorageService.readUrl(
          LocationCategory.DirectMessage,
          locationId,
          chatDocumentViewerState.stored_filename,
          pharmacyId,
        );
        setUrl(urlResponse.data.url);
      } catch (err) {}
    };

    if (chatDocumentViewerState && chatDocumentViewerState.show) {
      getAndSetUrl();
    }
  }, [chatDocumentViewerState.show]);

  if (!chatDocumentViewerState) {
    return null;
  }

  const height = Dimensions.get('window').height * HEIGHT_PERCENT;
  const width = Dimensions.get('window').width * WIDTH_PERCENT;
  const isPdf = chatDocumentViewerState.name.endsWith('pdf');

  return (
    <Portal>
      <Modal
        visible={chatDocumentViewerState.show}
        contentContainerStyle={styles.imageContainer}
        onDismiss={() =>
          setChatDocumentViewerState({
            show: false,
            stored_filename: '',
            name: '',
          })
        }
      >
        <View style={styles.modalHeader}>
          <IconButton
            logger={{ id: 'image-download-button' }}
            style={{
              height: theme.getSpacing(32) + theme.getSpacing(4),
            }}
            onPress={() =>
              setChatDocumentViewerState({
                show: false,
                stored_filename: '',
                name: '',
              })
            }
            icon={XIcon}
          />
          {isPdf ? (
            <embed src={url} width={width} height={height} />
          ) : (
            <>
              <View style={styles.downloadBar}>
                <Text>Download {chatDocumentViewerState.name}</Text>
                <IconButton
                  logger={{ id: 'image-download-button' }}
                  style={{
                    height: theme.getSpacing(32) + theme.getSpacing(4),
                  }}
                  onPress={() =>
                    void downloadAttachment(
                      chatDocumentViewerState.stored_filename,
                    )
                  }
                  icon={DownloadIcon}
                />
              </View>
            </>
          )}
        </View>
      </Modal>
    </Portal>
  );
};

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    backgroundColor: theme.palette.white,
    padding: theme.getSpacing(16),
    margin: theme.getSpacing(16),
    borderRadius: theme.roundness,
    justifyContent: 'center',
    alignSelf: 'center',
    maxHeight: Dimensions.get('window').height,
    maxWidth: Dimensions.get('window').width,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  downloadBar: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default ChatDocumentViewer;
