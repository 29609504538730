import React from 'react';
import { Provider } from 'react-native-paper';
import type { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { ThreedotsIcon } from '../../../icons';
import { IconButton } from '../../icon-button';
import { makeStyles, useTheme } from '../../../theme';
import { Menu, MenuItem } from 'react-native-material-menu';
import { Icon } from '../../icon';

export const ToolbarMenu: React.FC<ToolbarMenuProps> = ({
  options,
  anchorPosition = 'right',
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const [visible, setVisible] = React.useState(false);

  const closeMenu = () => setVisible(false);

  const handleOnPress = (onPress: () => void) => {
    if (onPress) onPress();
    closeMenu();
  };

  return (
    <Provider theme={theme}>
      <Menu
        visible={visible}
        onRequestClose={() => setVisible(false)}
        anchor={
          <IconButton
            icon={ThreedotsIcon}
            logger={{ id: 'pharmacy-menu-icon-button' }}
            onPress={() => setVisible(true)}
            color={theme.palette.white}
            testID={'TasksActionButtonToolbarMenu'}
            size={24}
            style={styles.iconButtonSize}
          />
        }
        style={{
          ...styles.hamburgerMenu,
          marginLeft: anchorPosition === 'right' ? 30 : -195,
        }}
      >
        {options.map((op, index) => (
          <MenuItem
            key={index}
            {...op}
            onPress={() => handleOnPress(op.onPress)}
            textStyle={styles.menuSpacing}
          >
            <View style={styles.contentContainer}>
              {op?.icon ? (
                <View style={styles.iconContainer}>
                  <Icon
                    icon={op.icon}
                    size={24}
                    color={theme.palette.gray[500]}
                  />
                </View>
              ) : null}
              <Text style={styles.itemText}>{op.title}</Text>
            </View>
          </MenuItem>
        ))}
      </Menu>
    </Provider>
  );
};

const useStyles = makeStyles((theme) => ({
  hamburgerMenu: {
    borderColor: theme.palette.gray[200],
    borderWidth: 1,
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  menuSpacing: {
    paddingLeft: theme.getSpacing(8),
    paddingRight: theme.getSpacing(8),
  },
  iconContainer: {
    width: 40,
    marginLeft: theme.getSpacing(8),
    marginRight: theme.getSpacing(8),
  },
  itemText: {
    width: 100,
    marginLeft: theme.getSpacing(8),
    marginRight: theme.getSpacing(8),
    fontSize: theme.getSpacing(16),
    lineHeight: 18,
  },
  iconButtonSize: {
    height: 24,
    width: 24,
    margin: 0,
  },
}));

export interface ToolbarMenuProps {
  options: Array<ToolbarMenuItemProps>;
  anchorPosition?: 'left' | 'right';
}

export const ToolbarMenuIconActionButtonsTestIDs = {
  label: 'toolbar-menu-label',
};

export interface ToolbarMenuItemProps {
  onPress: () => void;
  title: string;
  icon?: React.FunctionComponent;
  disabled?: boolean;
}
