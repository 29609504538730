import { Modal } from 'assets/components/modal';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';

import {
  CreateClosureDto,
  CreateClosuresRequestDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { PlusCircleIcon, TrashWithStripesIcon } from 'assets/icons';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { useFieldArray, useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import { zIndexAuto } from '../../../../common/theme';
import { DateTimePickerField } from '../../../../components/DateTimePickerField';
import {
  TimeRangeForm,
  convertTimeRangeToISOString,
  extractHoursAndMinutesFromISOString,
  formatDate,
} from '../../../../components/Settings/utils/TimeRange';
import {
  setClosures,
  setShowModalAdditionalClosures,
} from '../pharmacy-settings-actions';
import { usePharmacySettingsState } from '../pharmacy-settings-store';

const emptyClosure: ClosureHours = {
  name: '',
  enabled: false,
  date: '',
  dayHours: {
    startHour: '',
    endHour: '',
  },
};

export const AdditionalClosuresModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showModalAdditionalClosures, closures } = usePharmacySettingsState();
  const styles = useStyles();
  const theme = useTheme();

  const methods = useForm<DepartmentHoursFormProps>({
    defaultValues: { closures: [emptyClosure] },
    mode: 'onChange',
  });
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'closures',
  });

  useEffect(() => {
    if (closures.length === 0) {
      methods.reset({ closures: [emptyClosure] });
    } else {
      methods.reset({
        closures: closures.map((closure) => {
          const dateString: string = closure.timeRange.date
            ? formatDate(new Date(closure.timeRange.date))
            : '';
          return {
            enabled: closure.enabled || false,
            name: closure.name || '',
            date: dateString,
            dayHours: convertTimeRangeToISOString(closure.timeRange),
          };
        }),
      });
    }
  }, [closures]);

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const createClosures: CreateClosureDto[] = formValue.closures.map(
      (closure) => {
        const startHour = extractHoursAndMinutesFromISOString(
          closure.dayHours.startHour,
        );
        const endHour = extractHoursAndMinutesFromISOString(
          closure.dayHours.endHour,
        );
        return {
          name: closure.name,

          time_range: {
            date: closure.date,
            start_hour: startHour.hours,
            start_minute: startHour.minutes,
            end_hour: endHour.hours,
            end_minute: endHour.minutes,
          },
        };
      },
    );
    const closures: CreateClosuresRequestDto = { closures: createClosures };
    setClosures(closures);
    setShowModalAdditionalClosures(false);
  };

  const closeModal = () => {
    setShowModalAdditionalClosures(false);
  };

  return (
    <Modal
      title={getText('pharmacy-additional-closures')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-additional-closures-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-additional-closures-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-additional-closures-form-ok-button-modal' },
        hierarchy: 'pharmacy-primary',
        text: getText('ok'),
      }}
      show={showModalAdditionalClosures}
      isScrollable={true}
      size="lg"
    >
      <Form methods={methods}>
        {fields.map((field, index) => (
          <>
            <Form.Row style={styles.row}>
              <Form.Column>
                <CheckboxField
                  key={`${field.id}.enabled`}
                  name={`closures.${index}.enabled`}
                  label={getText('pharmacy-additional-closures-active')}
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
              <Form.Column style={{ alignItems: 'flex-end' }}>
                <TouchableOpacity
                  style={[styles.topBarButton]}
                  onPress={() => remove(index)}
                >
                  <Icon
                    icon={TrashWithStripesIcon}
                    color={theme.palette.gray[700]}
                    size={20}
                  />
                </TouchableOpacity>
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.row}>
              <Form.Column>
                <TextField
                  key={`${field.id}.name`}
                  style={styles.inputStyle}
                  name={`closures.${index}.name`}
                  type="text"
                  label={getText('pharmacy-additional-label')}
                  rules={{
                    required: getText('pharmacy-additional-name-required'),
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={{ ...styles.row }}>
              <Form.Row style={styles.dateStyle}>
                <TextField
                  key={`${field.id}.date`}
                  style={styles.inputStyle}
                  name={`closures.${index}.date`}
                  type="date"
                  rules={{
                    required: getText('pharmacy-additional-date-required'),
                  }}
                />
                <View style={{ marginLeft: theme.getSpacing(16) }}>
                  <DateTimePickerField
                    key={`${field.id}.startHour`}
                    name={`closures.${index}.dayHours.startHour`}
                    type="time"
                    rules={{
                      required: getText('pharmacy-additional-hour-required'),
                    }}
                  />
                </View>
                <Text style={styles.separatorStyle}>-</Text>
                <View>
                  <DateTimePickerField
                    key={`${field.id}.endHour`}
                    name={`closures.${index}.dayHours.endHour`}
                    type="time"
                    rules={{
                      required: getText('pharmacy-additional-hour-required'),
                    }}
                  />
                </View>
              </Form.Row>
            </Form.Row>
          </>
        ))}
        <Form.Row style={styles.row}>
          <TouchableOpacity
            style={[styles.topBarButton]}
            onPress={() => append(emptyClosure)}
          >
            <Icon
              icon={PlusCircleIcon}
              color={theme.palette.primary[600]}
              size={20}
            />
            <Text style={styles.topBarText}>{getText('add-another')}</Text>
          </TouchableOpacity>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface ClosureHours {
  name: string;
  enabled: boolean;
  date: string;
  dayHours: TimeRangeForm;
}

interface DepartmentHoursFormProps {
  closures: ClosureHours[];
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(8),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 54,
    width: '100%',
  },
  textWrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  weekStyle: {
    flexDirection: 'row',
    flex: 1,
    zIndex: zIndexAuto,
  },
  hoursGroupStyle: {
    flex: 4,
    zIndex: zIndexAuto,
  },
  hoursStyle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    marginLeft: theme.getSpacing(8),
  },
  row: {
    display: 'flex',
    flex: 1,
    zIndex: zIndexAuto,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[200],
    paddingBottom: theme.getSpacing(24),
  },
  closedTextStyle: {
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[500],
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.getSpacing(8),
  },
  separatorStyle: {
    color: theme.palette.gray[400],
    fontWeight: '600',
    margin: theme.getSpacing(8),
    marginTop: theme.getSpacing(16),
  },
  topBarButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  topBarText: {
    color: theme.palette.primary[600],
    fontWeight: '500',
    fontSize: 16,
    marginLeft: theme.getSpacing(4),
  },
  dateStyle: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
  },
}));
