import React, { useState } from 'react';
import { IconButton } from 'assets/components/icon-button';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseCircleIcon,
  SearchIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import theme, { makeStyles } from 'assets/theme';
import { Text, View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { useVideosState } from '../../store/videos-store';
import { useDebounceEffect } from '../../common/hooks/useDebounceEffect';

const SearchFilter: React.FC = () => {
  const styles = useStyles();
  const PAGE_SIZE = 25;
  const { totalCount, page, setPage, setSearch, search } = useVideosState();

  useDebounceEffect(() => {
    setSearch(search);
  }, [search]);

  const goToPreviousPage = () => {
    setPage((page || 0) - 1);
  };

  const goToNextPage = () => {
    setPage((page || 0) + 1);
  };

  const PaginationDisplay = () => {
    return (
      <View style={styles.paginationContainer}>
        <Text style={{ marginRight: theme.getSpacing(8) }}>
          {`${PAGE_SIZE * ((page || 0) - 1) + 1} - ${Math.min(
            totalCount || 0,
            PAGE_SIZE * (page || 0),
          )} of ${totalCount}`}
        </Text>
        <IconButton
          disabled={page === 1}
          icon={ChevronLeftIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-back' }}
          onPress={goToPreviousPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: 'unset',
            width: 'unset',
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
        <IconButton
          disabled={PAGE_SIZE * (page || 0) >= (totalCount || 0)}
          icon={ChevronRightIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-forward' }}
          onPress={goToNextPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: 'unset',
            width: 'unset',
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.titleContainer}>
      <Text style={styles.title}>{getText('videos')}</Text>
      <View>
        <TextInput
          placeholder={getText('search')}
          autoComplete="off"
          autoCapitalize="none"
          style={{
            height: 44,
            width: 320,
            backgroundColor: '#EAF1F4',
          }}
          mode="outlined"
          outlineColor={theme.palette.white}
          activeOutlineColor={theme.colors.primary}
          value={search ?? ''}
          left={
            !search ? (
              <TextInput.Icon
                name={SearchIcon}
                color={theme.palette.gray[500]}
                size={22}
                forceTextInputFocus={false}
                style={{ top: 4 }}
              />
            ) : null
          }
          right={
            search ? (
              <TextInput.Icon
                name={CloseCircleIcon}
                color={theme.palette.gray[500]}
                size={20}
                forceTextInputFocus={false}
                style={{ top: 4 }}
                onPress={() => setSearch('')}
              />
            ) : null
          }
          onChangeText={setSearch}
          children={undefined}
        />
      </View>
      <View style={styles.pagination}>
        {page && totalCount ? <PaginationDisplay /> : <View />}
      </View>
    </View>
  );
};

export default SearchFilter;

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(8),
    marginTop: theme.getSpacing(16),
    marginLeft: theme.getSpacing(24),
    width: '90%',
  },
  title: {
    fontSize: 25,
    marginRight: theme.getSpacing(24),
    alignItems: 'center',
    display: 'flex',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  paginationContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
