import { FunctionComponent, PropsWithChildren } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { CheckboxBase } from '../../../../../packages/assets/components/checkbox/CheckboxBase';
import { Text } from '../../../../../packages/assets/components/text';
import { makeStyles } from '../../../../../packages/assets/theme';
import { TaskFilters } from './tasks-filters-store';

export const TasksFilterCheckbox: FunctionComponent<
  PropsWithChildren<TasksFilterCheckboxProps>
> = ({ active, name, value, disabled, onPress }) => {
  const styles = useStyles();
  return (
    <TouchableOpacity onPress={() => onPress(value)} disabled={disabled}>
      <View style={[styles.filter, disabled && styles.filterDisabled]}>
        <CheckboxBase onPress={() => onPress(value)} isChecked={active} />
        <Text style={styles.filterText}>{name}</Text>
      </View>
    </TouchableOpacity>
  );
};

export interface TasksFilterCheckboxProps {
  active: boolean;
  name: string;
  field: keyof TaskFilters;
  value: TaskFilters;
  disabled?: boolean;
  onPress: (filter: TaskFilters) => void;
}

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(8),
    paddingHorizontal: theme.getSpacing(24),
    cursor: 'pointer',
  },
  filterDisabled: {
    opacity: 0.5,
  },
  filterText: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    marginLeft: theme.getSpacing(8),
  },
}));
