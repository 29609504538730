import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { Icon } from 'assets/components/icon';

export const Tab: FunctionComponent<PropsWithChildren<TabProps>> = ({
  title,
  isActive,
  id,
  icon,
  onPress,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <TouchableOpacity onPress={() => onPress(id)}>
      <View style={[styles.container, isActive && styles.containerActive]}>
        {icon && (
          <View style={styles.iconContainer}>
            <Icon
              icon={icon}
              size={15}
              color={
                isActive
                  ? theme.palette.primary[600]
                  : theme.palette.primary[700]
              }
            />
          </View>
        )}
        <Text style={[styles.text, isActive && styles.textActive]}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(16),
    borderTopLeftRadius: theme.roundness,
    borderTopRightRadius: theme.roundness,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerActive: {
    backgroundColor: theme.palette.white,
    boxShadow: '0px -3px 12px rgba(0, 0, 0, 0.04)',
  },
  iconContainer: {
    marginRight: theme.getSpacing(8),
  },
  text: {
    color: theme.palette.primary[700],
  },
  textActive: {
    color: theme.palette.primary[600],
  },
}));

export interface TabProps {
  title: string;
  isActive: boolean;
  id: string;
  icon?: FunctionComponent;
  onPress: (pressedTab: string) => void;
}
