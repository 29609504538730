import React, {
  FunctionComponent,
  PropsWithChildren,
  Children,
  cloneElement,
  ReactElement,
} from 'react';
import { useTheme } from 'assets/theme';
import { StyleProp, ViewStyle, View } from 'react-native';
import { FormColumnProps } from './FormColumn';

export const FormRow: FunctionComponent<PropsWithChildren<FormRowProps>> = ({
  children,
  style,
  compact,
}) => {
  const theme = useTheme();
  const childrenArray = Children.toArray(children);
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          marginBottom: compact ? theme.getSpacing(8) : theme.getSpacing(16),
        },
        style,
      ]}
    >
      {Children.map(childrenArray, (child, index) => (
        <React.Fragment key={index}>
          {cloneElement(child as React.ReactElement<FormColumnProps>, {
            // assign the `last` prop automatically so components using FormColumn never have to assign this
            last: index === childrenArray.length - 1,
          })}
        </React.Fragment>
      ))}
    </View>
  );
};

export interface FormRowProps {
  style?: StyleProp<ViewStyle>;
  /**
   * Create less spacing, useful for a group of checkboxes
   */
  compact?: boolean;
  children: ReactElement<FormColumnProps>[] | ReactElement<FormColumnProps>;
}
