import { View } from 'react-native';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { filterXSS } from 'xss';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { ChevronLeftIcon } from 'assets/icons/ChevronLeftIcon';
import { useNavigation } from '@react-navigation/native';
import { FormsDrawerNavigationProp } from '../../layout/FormsDrawer';
import { DownloadIcon, TrashWithStripesIcon } from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import {
  deleteSubmission,
  getSubmission,
  getSubmissionPDF,
} from '../../screens/forms/view-submission-actions';
import { useViewSubmissionStore } from '../../screens/forms/view-submission-store';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { formatDate, formatDateTime } from '../../common/datetime-utils';
import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { Text } from 'assets/components/text';
import { useToast } from '../../common/hooks/useToast';
import { Lightbox } from '../../common/Lightbox';
import moment from 'moment';
import { downloadFile, getDateTimeFilename } from '../../common/file-utils';
import '../../screens/forms/answer-input-table.css';
import { ampli } from '../../common/ampliPharmacist';
import { getText } from 'assets/localization/localization';

export const Submission: FunctionComponent<
  PropsWithChildren<SubmissionProps>
> = ({ submissionId, formId, withDelete = true }) => {
  const styles = useStyles();
  const theme = useTheme();

  const { toast } = useToast();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    getSubmission(formId, submissionId);
    ampli.formSubmissionViewed({
      formSubmissionViewedTime: new Date().toISOString(),
      activeStatus: true,
      createTime: 'formCreated',
      downloadTime: '',
      formId: '',
      formType: '',
      readStatus: true,
      updateTime: '',
    });
  }, [formId, submissionId]);

  const { submission, status } = useViewSubmissionStore();

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const goBack = () => {
    navigation.navigate('submissions-list');
  };

  const displayImage = (
    src: string,
    type: string,
    alt?: string,
    caption?: string,
  ) => (
    <Lightbox
      images={[{ src, alt, caption }]}
      galleryProps={{
        options: {
          initialZoomLevel: 'fit',
          secondaryZoomLevel: 1,
        },
      }}
      imageStyle={{
        maxWidth: 200,
        maxHeight: 200,
      }}
      imgBgWhite={type === 'Signature'}
    />
  );

  const showDeleteModal = () => {
    setShowDeleteConfirmation(true);
  };

  const deleteSubmissionCloseModal = () => {
    deleteSubmission(formId, submissionId, navigation);
    setShowDeleteConfirmation(false);
  };

  const displayAnswer = (
    answer: string,
    answerType: string,
    answerTitle?: string,
  ) => {
    answer = answerType.includes('datetime') ? formatDate(answer) : answer;

    if (
      (answerType === 'Signature' || answerType === 'ImageUpload') &&
      answer.length
    ) {
      return displayImage(answer.split(',')[0], answerType, answerTitle);
    }

    if (answerType === 'DateOfBirth') {
      return moment(answer).format('MM/DD/YYYY');
    }

    if (answerType === 'InputTable') {
      return (
        <div
          className="answer-input-table"
          dangerouslySetInnerHTML={{ __html: filterXSS(answer.toString()) }}
        ></div>
      );
    }

    return answer.length
      ? answer.toString().replaceAll('<br>', '\n')
      : getText('not-entered');
  };

  const downloadSubmission = async () => {
    toast(getText('preparing-submission'), {
      type: 'info',
      content: getText('download-will-begin'),
    });
    setIsDownloadLoading(true);
    const data = await getSubmissionPDF(formId, submissionId);

    data &&
      downloadFile(
        data,
        getDateTimeFilename(
          `${submission?.form_name} ${submission?.name}`,
          'pdf',
        ),
      );

    setIsDownloadLoading(false);
  };

  return submission && status !== 'loading' ? (
    <View style={[styles.container, !withDelete && { marginHorizontal: 0 }]}>
      {withDelete && (
        <View style={styles.header}>
          <IconButton
            size={25}
            logger={{ id: 'go-back-submissions-button' }}
            icon={ChevronLeftIcon}
            onPress={goBack}
          />
          <Text style={styles.title} selectable>
            {submission.form_name}
          </Text>
          <Button
            hierarchy="secondary-gray"
            mode="outlined"
            size="medium"
            logger={{ id: 'delete-submission-button' }}
            icon={() => (
              <TrashWithStripesIcon size={20} color={theme.palette.gray[700]} />
            )}
            onPress={() => showDeleteModal()}
          >
            {getText('delete')}
          </Button>

          <Button
            hierarchy="pharmacy-primary"
            size="medium"
            logger={{ id: 'download-submission-button' }}
            onPress={() => downloadSubmission()}
            icon={() => <DownloadIcon size={20} color={theme.palette.white} />}
            loading={isDownloadLoading}
          >
            {getText('download-pdf')}
          </Button>
        </View>
      )}

      <View style={withDelete ? styles.submissionContainer : undefined}>
        <PharmacyConfirmationModal
          show={showDeleteConfirmation}
          onAccepted={() => deleteSubmissionCloseModal()}
          onDismiss={() => setShowDeleteConfirmation(false)}
          message={getText('delete-submission-confirmation')}
        />
        <View style={styles.field}>
          <Text style={styles.label} selectable>
            {getText('submitted-on')}
          </Text>
          <Text style={styles.answer} selectable>
            {formatDateTime(submission.created_at)}
          </Text>
        </View>

        {submission.answers.map((answer, index) => (
          <View key={index} style={styles.field}>
            <Text style={styles.label} selectable>
              {answer.title}
            </Text>
            <Text
              selectable={true}
              style={[
                styles.answer,
                !answer.answer.length && styles.greyedOut,
                answer.type === 'InputTable' && styles.inputTableAnswer,
              ]}
            >
              {displayAnswer(answer.answer, answer.type, answer.title)}
            </Text>
          </View>
        ))}
      </View>
    </View>
  ) : (
    <LoadingOverlay />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginHorizontal: theme.getSpacing(32),
    marginBottom: theme.getSpacing(32),
    marginTop: theme.getSpacing(16),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(16),
    gap: theme.getSpacing(8),
  },
  title: {
    marginLeft: theme.getSpacing(16),
    fontSize: 25,
    flexGrow: 1,
  },
  answer: {
    fontSize: 16,
    lineHeight: 24,
    overflow: 'hidden',
    color: theme.palette.gray[700],
    maxWidth: 600,
  },
  inputTableAnswer: {
    maxWidth: 'none',
  },
  greyedOut: {
    color: theme.palette.gray['400'],
  },
  field: {
    fontSize: 16,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: theme.getSpacing(8),
    marginBottom: theme.getSpacing(24),
  },
  label: {
    ...theme.fonts.medium,
    fontSize: 16,
    lineHeight: 18,
    color: theme.palette.gray[900],
    maxWidth: 600,
  },
  submissionContainer: {
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
    paddingTop: theme.getSpacing(32),
  },
}));

interface SubmissionProps {
  submissionId: string;
  formId: string;
  withDelete?: boolean;
}
