import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { GridApi } from '@ag-grid-community/core';

import { getText } from 'assets/localization/localization';
import { Icon } from '../../icon';
import { CheckIcon } from '../../../icons';
import { makeStyles, useTheme } from '../../../theme';
import { Text } from '../../text';
import { Button } from '../../button/Button';

export const ToolbarSelectAllBox: FunctionComponent<
  PropsWithChildren<ToolbarSelectAllBoxProps>
> = ({ api, onSelectAll, onDeselectAll, isGlobalSelect }) => {
  const styles = useStyles();
  const theme = useTheme();

  const selectedItems = isGlobalSelect
    ? api?.paginationGetRowCount()
    : api?.getRenderedNodes().length;

  const title = isGlobalSelect
    ? getText('selecting-all-pages', { count: selectedItems })
    : getText('selecting-current-page', { count: selectedItems });

  const selectButtonTitle = isGlobalSelect
    ? getText('select-current-page')
    : getText('select-all-pages');

  return (
    <View style={styles.root}>
      <View style={{ marginRight: 10 }}>
        <Icon icon={CheckIcon} color={theme.palette.gray[600]} size={16} />
      </View>
      <View style={styles.textContainer}>
        <View>
          <Text style={styles.title}>{title}</Text>
        </View>

        <View>
          <Button
            style={styles.buttonBorderColor}
            labelStyle={styles.buttonLabel}
            onPress={onSelectAll}
            hierarchy="secondary-gray"
            logger={{ id: 'select-all-box-select-global' }}
          >
            {selectButtonTitle}
          </Button>
        </View>

        <View>
          <Text
            style={[
              styles.title,
              {
                marginHorizontal: theme.getSpacing(8),
              },
            ]}
          >
            |
          </Text>
        </View>
        <View>
          <Button
            style={styles.buttonBorderColor}
            labelStyle={styles.buttonLabel}
            onPress={onDeselectAll}
            hierarchy="secondary-gray"
            logger={{ id: 'select-all-box-unselect-global' }}
          >
            {getText('unselect-all')}
          </Button>
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    color: theme.palette.primary[600],
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    letterSpacing: 0,
  },
  buttonBorderColor: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    marginLeft: theme.getSpacing(8),
  },
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[600],
  },
  root: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(16),
    alignItems: 'center',
    backgroundColor: theme.palette.warning[100],
    borderRadius: theme.roundness,
    padding: theme.getSpacing(16),
    marginRight: theme.getSpacing(4),
  },
  textContainer: {
    flexDirection: 'row',
  },
}));

export interface ToolbarSelectAllBoxProps {
  api: GridApi | undefined;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  isGlobalSelect: boolean;
}
