import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Button } from 'assets/components/button';
import { TextField } from 'assets/components/text-field';
import { login, LoginForm } from './login-actions';
import { useForm } from 'assets/form';
import { LoginTestIDs } from './LoginTestIDs';
import { useLoginState } from './login-store';
import { ScreenContainer, Form } from 'assets/layout';
import { TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as validate from '@digitalpharmacist/validation-dp';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  RootNavigationParamList,
  RootStackNavigationProp,
} from '../../navigation/RootNavigation';
import { useNavigation } from '@react-navigation/native';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { AddPersonIcon, UserIcon, UserPlusIcon } from 'assets/icons';
import { PharmacyUserDto } from '@digitalpharmacist/users-service-client-axios';
import { QuickLogin } from './QuickLogin';
import { StorageKeys } from '../../../enums/storage-keys';

export const Login: FunctionComponent<PropsWithChildren<LoginProps>> = (
  props,
) => {
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { status, error } = useLoginState();
  const navigation = useNavigation<RootStackNavigationProp>();
  const [displayQuickUsers, setDisplayQuickUsers] = useState<boolean>(true);
  const [savedUsers, setSavedUsers] = useState<PharmacyUserDto[]>([]);
  const handleSubmit = async () => {
    login(methods.getValues());
  };

  useEffect(() => {
    const init = async () => {
      const userInfo = await AsyncStorage.getItem(StorageKeys.UserInfo);
      if (userInfo) {
        const storageUsers = JSON.parse(userInfo);
        const usersArray = [];
        for (const user in storageUsers) {
          usersArray.push(storageUsers[user]);
        }
        setSavedUsers(usersArray);
      } else {
        setDisplayQuickUsers(false);
      }
    };
    init();
  }, []);

  const renderQuickUsers = () => {
    return savedUsers.map((user: any) => (
      <QuickLogin key={user.userInfo.id} user={user.userInfo} />
    ));
  };

  return (
    <ScreenContainer showVersion={false} style={{ marginTop: '10%' }}>
      <Text
        style={{ fontSize: 32, marginBottom: theme.getSpacing(32) }}
        selectable
      >
        {getText('login')}
      </Text>
      {displayQuickUsers && savedUsers.length ? (
        <>
          <View style={styles.alertView}>
            <Form.Alert
              title={getText('authentication-failed-generic')}
              intent="error"
              visible={!!error}
            />
          </View>
          {renderQuickUsers()}
        </>
      ) : (
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText('email')}
                name="email"
                rules={{
                  required: getText('email-is-required'),
                  validate: {
                    value: () => {
                      return validate.isEmail(methods.getValues().email)
                        ? true
                        : getText('email-is-not-valid');
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === 'loading'}
                testID={LoginTestIDs.usernameInput}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText('password')}
                type="password"
                name="password"
                rules={{
                  required: getText('password-is-required'),
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === 'loading'}
                testID={LoginTestIDs.passwordInput}
              />
            </Form.Column>
          </Form.Row>
          <Form.Actions>
            <View style={{ marginBottom: theme.getSpacing(16) }}>
              <Button
                testID={LoginTestIDs.loginButton}
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="primary"
                loading={status === 'loading'}
                logger={{ id: 'login-button' }}
                style={{
                  backgroundColor: theme.palette.primary[600],
                  borderColor: theme.palette.primary[600],
                  width: 120,
                }}
              >
                {getText('login')}
              </Button>
            </View>
            <View
              style={{
                marginBottom: theme.getSpacing(16),
                alignItems: 'flex-start',
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('reset-link');
                }}
              >
                <Text
                  style={{
                    color: theme.palette.primary[700],
                    fontSize: 16,
                    marginTop: theme.getSpacing(8),
                    marginBottom: theme.getSpacing(24),
                    alignSelf: 'center',
                  }}
                  selectable
                >
                  {getText('forgot-password')}
                </Text>
              </TouchableOpacity>
            </View>
          </Form.Actions>
        </Form>
      )}
      {displayQuickUsers && (
        <TouchableOpacity
          style={{ width: '50%', marginTop: theme.getSpacing(16) }}
          onPress={() => {
            setDisplayQuickUsers(false);
          }}
        >
          <Text
            style={{
              color: theme.palette.primary[700],
              fontSize: 16,
            }}
          >
            <View style={{ top: theme.getSpacing(4), marginRight: 8 }}>
              <UserPlusIcon size={22} color={theme.colors.primary[700]} />
            </View>
            {getText('another-account')}
          </Text>
        </TouchableOpacity>
      )}
    </ScreenContainer>
  );
};
const useStyles = makeStyles(() => ({
  alertView: {
    width: '90%',
  },
}));

type LoginProps = NativeStackScreenProps<RootNavigationParamList, 'login'>;
{
}
