import React from 'react';
import { Button } from 'assets/components/button';
import { StyleSheet, View } from 'react-native';
import { PlusIcon } from 'assets/icons';
import { useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { updateShowPMSModalState } from '../pms-integrations-settings-actions';
import { getText } from 'assets/localization/localization';

export default function PMSIntegrationEmpty() {
  const theme = useTheme();
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 265,
    },
    text: {
      fontSize: 20,
      fontWeight: '600',
      lineHeight: 30,
      marginTop: theme.getSpacing(8),
      marginBottom: theme.getSpacing(24),
    },
  });

  const handleSetupNewIntegration = () => {
    updateShowPMSModalState(true);
  };

  return (
    <View style={styles.container}>
      <PlusIcon size={60} color={theme.palette.gray[400]}></PlusIcon>
      <Text style={styles.text}>{getText('integration-missing')}</Text>
      <Button
        onPress={handleSetupNewIntegration}
        logger={{
          id: 'add-integration-button',
        }}
        hierarchy="secondary"
        size="small"
      >
        {getText('setup-integration')}
      </Button>
    </View>
  );
}
