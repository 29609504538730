import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { makeStyles } from '../../theme';
import { Text } from '../text';
import {
  CheckboxInput,
  CheckboxInputProps,
  CheckboxInputMode,
} from './CheckboxInput';

export const CheckboxField: FunctionComponent<
  PropsWithChildren<CheckboxFieldProps>
> = ({
  name,
  rules,
  children,
  onPress,
  mode = CheckboxInputMode.OUTLINE,
  ...rest
}) => {
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('Checkbox field must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <View>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <CheckboxInput
              {...rest}
              mode={mode}
              onPress={(checked: boolean) => {
                onChange(checked);
                onPress?.(checked);
              }}
              checked={value}
            >
              {children}
            </CheckboxInput>
          </>
        )}
        name={name}
        rules={rules}
      />

      {!!error && (
        <Text testID={CheckboxFieldTestIDs.error} style={styles.errorMessage}>
          {error.message}
        </Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(8),
  },
}));

export interface CheckboxFieldProps extends CheckboxInputProps {
  name: string;
  rules?: RegisterOptions;
}

export const CheckboxFieldTestIDs = {
  error: 'checkboxField-error-message',
};
