import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import { CheckboxBase } from './CheckboxBase';
import { makeStyles } from '../../theme';
import { Text } from '../text';

export const CheckboxInput: FunctionComponent<
  PropsWithChildren<CheckboxInputProps>
> = ({
  checked = false,
  disabled = false,
  roundIcon = false,
  mode = CheckboxInputMode.FLAT,
  hintMessage,
  label,
  testID,
  onPress,
  children,
}) => {
  const styles = useStyles();
  const [state, setState] = useState(checked);
  const handleChange = () => {
    const newState = !state;
    setState(newState);
    if (onPress) {
      onPress(newState);
    }
  };

  useEffect(() => {
    if (state !== checked) {
      setState(checked);
    }
  }, [checked]);

  return (
    <CheckboxWrapper
      style={[
        mode === CheckboxInputMode.OUTLINE && styles.container,
        checked && !disabled && styles.borderChecked,
        disabled &&
          mode === CheckboxInputMode.OUTLINE &&
          styles.backgroundDisabled,
      ]}
      mode={mode}
      onPress={handleChange}
      disabled={disabled}
    >
      <View style={styles.checkboxContainer}>
        <>
          <CheckboxBase
            onPress={handleChange}
            isChecked={state}
            disabled={disabled}
            testID={testID}
            roundIcon={roundIcon}
          />
          {label && (
            <Text testID={CheckboxInputTestIDs.label} style={styles.label}>
              {label}
            </Text>
          )}
          {children}
        </>
      </View>
      {hintMessage && (
        <Text
          testID={CheckboxInputTestIDs.hintMessage}
          style={styles.hintMessage}
        >
          {hintMessage}
        </Text>
      )}
    </CheckboxWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    color: theme.palette.gray[700],
    marginLeft: theme.getSpacing(8),
    flex: 1,
  },
  hintMessage: {
    color: theme.palette.gray[500],
    fontSize: 16,
    marginLeft: theme.getSpacing(32),
  },
  container: {
    borderWidth: 1,
    padding: theme.getSpacing(16),
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[300],
  },
  borderChecked: {
    borderColor: theme.palette.primary[600],
  },
  backgroundDisabled: {
    backgroundColor: theme.palette.gray[50],
  },
}));

export interface CheckboxInputProps {
  checked?: boolean;
  label?: string;
  hintMessage?: string;
  onPress?: (checked: boolean) => void;
  disabled?: boolean;
  roundIcon?: boolean;
  testID?: string;
  mode?: CheckboxInputMode;
}

export const CheckboxInputTestIDs = {
  label: 'checkbox-label',
  hintMessage: 'checkbox-hint-message',
};

interface CheckboxWrapperProps {
  mode?: CheckboxInputMode;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  onPress?: () => void;
}

const CheckboxWrapper: FunctionComponent<
  PropsWithChildren<CheckboxWrapperProps>
> = ({ children, mode, style, disabled, onPress }) => {
  // TODO: find a better way to write this with generics
  return mode === CheckboxInputMode.FLAT ? (
    <View style={style}>{children}</View>
  ) : (
    <TouchableOpacity disabled={disabled} style={style} onPress={onPress}>
      {children}
    </TouchableOpacity>
  );
};

export enum CheckboxInputMode {
  FLAT = 'flat',
  OUTLINE = 'outline',
}
