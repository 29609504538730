import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { makeStyles, useTheme } from '../../theme';
import { logEvent } from '../../logging/logger';
import { Switch } from 'react-native-paper';

export const ToggleSwitch: FunctionComponent<
  PropsWithChildren<ToggleSwitchProps>
> = ({ logger, disabled = false, value = undefined, id, onPress }) => {
  const theme = useTheme();
  const styles = useStyles();
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const toggleSwitch = (event: boolean) => {
    // If the value isn't controlled we need to trigger the native event
    if (value === undefined) {
      setIsSwitchOn(!isSwitchOn);
    }

    logEvent(logger.id, {
      data: typeof logger.data === 'function' ? logger.data() : logger.data,
    });

    onPress(event);
  };

  useEffect(() => {
    if (value !== undefined) {
      setIsSwitchOn(!value);
      logEvent(logger.id, {
        data: typeof logger.data === 'function' ? logger.data() : logger.data,
      });
    }
  }, [value]);

  return (
    <Switch
      trackColor={{
        false: theme.palette.gray[200],
        true: disabled
          ? theme.palette.gray[200]
          : theme.colors.pharmacySecondary,
      }}
      onValueChange={toggleSwitch as any}
      value={!isSwitchOn}
      thumbColor={disabled ? theme.colors.disabled : theme.palette.white}
      color={theme.palette.white}
      style={styles.switch}
      disabled={disabled}
      testID="toggle-switch"
      accessibilityLabel={id}
      accessibilityRole="switch"
      key={id}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  switch: {
    width: 36,
    height: 20,
    paddingVertical: theme.getSpacing(16),
    justifyContent: 'center',
    padding: 2,
  },
}));

export interface ToggleSwitchProps {
  logger: { id: string; data?: Object | (() => Object) };
  disabled?: boolean;
  value?: boolean;
  id?: string;
  onPress: (event: boolean) => void;
}

export const ToggleSwitchTestIDs = {
  toggleSwitch: 'toggle-switch',
};
