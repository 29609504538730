import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { StyleSheet, View } from 'react-native';

export const TaskMetaItem: FunctionComponent<
  PropsWithChildren<TaskMetaItemProps>
> = ({
  icon,
  size = 14,
  color,
  label,
  labelColor,
  backgroundColor = 'transparent',
  borderColor,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View
      style={[
        styles.taskMetaItem,
        {
          backgroundColor: backgroundColor,
          borderRadius: 18,
        },
        borderColor ? { borderColor: borderColor, borderWidth: 1 } : {},
      ]}
    >
      {icon ? (
        <Icon
          icon={icon}
          color={color ?? theme.palette.gray[700]}
          size={size}
        ></Icon>
      ) : null}
      <Text
        style={[
          { fontSize: size, color: labelColor ?? theme.palette.gray[700] },
          icon ? { marginLeft: theme.getSpacing(4) } : {},
        ]}
        selectable
      >
        {label}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  taskMetaItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: theme.getSpacing(4),
    paddingVertical: 2,
  },
}));

export interface TaskMetaItemProps {
  icon?: FunctionComponent;
  size?: number;
  color?: string;
  label: string;
  labelColor?: string;
  backgroundColor?: string;
  borderColor?: string;
}
